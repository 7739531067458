import '../App.css';
import { useEffect, useState } from "react";
import RaderChart from "./Chart";
import questionsData from "./Questions.json";
import Attribute from './Attribute';
import OptionToggle from "./OptionToggle"; 
import { ChakraProvider,Flex, Button,Box, FormControl, FormLabel, Input, Text,VStack,Stack,Alert, AlertIcon,} from "@chakra-ui/react";
import React from "react";


function LSM({ }) {

    const answers = [
        {id:"1", label:"1. Always true for me"},
        {id:"2", label: "2. Often true for me"}, 
        {id:"3", label: "3. Sometimes true for me"}, 
        {id:"4", label: "4. Rarely true for me"},
        {id:"5", label: "5. Never true for me"}
    ];
    
  const questions = questionsData;

  const [val, setVal] = useState("");
  const [transition, setTransition] = useState(true);
  const [answerList, setAnswerList] = useState([]);
  const [chartDisplay, setChartDisplay] = useState(false);
  const [questionState, setQuestionState] = useState(-1)
  const [attributeData, setAttributeData] = useState({selectedJob: '', selectedAge: '', selectedGender: ''});
  const [selectedOption, setSelectedOption] = useState(answers[0].id);

  console.log(answerList)



  React.useEffect(() => {
    if (questionState === -1) {
      window.scrollTo(0, 0);
    }
  }, [questionState]); 


  const handleOptionSelect = ((option) => {
    setAnswerList([...answerList, Number(option.id)]);
    setSelectedOption(option);
    setVal(option.label)
    setTimeout(()=>{
    setTransition(false);
      },1000);
    setTimeout(()=>{
        setTransition(true);
        setQuestionState(questionState+1);
        setVal("")
        setSelectedOption(answers[0].id)
      },1500);
    });

  const nextPage = (event)=>{
    setQuestionState(questionState+1);
  }
  
const fixResult = () =>{
    setChartDisplay(true)
  }

    if (questionState===-1){
      return(
      <Box backgroundColor= "#E3E7EB" textAlign="center" height="100vh" marginTop="60px" >
        <Text fontSize="25px" marginBottom="50px"paddingTop="50px" paddingLeft="20px" paddingRight="20px">We will now begin the mindfulness assessment.</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">Please read the following instructions before starting.</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">There are a total of 15 questions.</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">Please answer the questions on the next page intuitively without overthinking.</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">The page will automatically transition one second after making a selection.</Text>
        <Text fontSize="15px" paddingLeft="20px" paddingRight="20px">If you need to start over, please proceed to the last page and then return to the beginning.</Text>
        <Text fontSize="15px" marginBottom="50px" paddingLeft="20px" paddingRight="20px">This is not a test, and there are no right or wrong answers, so please feel free to respond.</Text>
        <Button
          onClick={nextPage}
          size="lg"
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          mt="5px"
          mb="100px"
          borderRadius="0"
          border="none"
          >Start
        </Button>
      </Box>
    )
    } else if (questionState===15 & chartDisplay===false){
      return(
      <div className="App">
        <Text fontSize="25px" marginTop="180px" marginBottom="50px">Completed! Thank you for your time and effort.</Text>
        <Button
          onClick={fixResult}
          size="lg"
          fontSize="20px"
          paddingTop="10px"
          paddingRight="15px"
          paddingBottom="10px"
          paddingLeft="15px"
          color="white"
          bg="#23A6BA"
          _hover={{ bg: "#2BB1C5" }}
          _active={{ bg: "#1E8A9D" }}
          marginTop={"30px"}
          marginBottom={"30px"}
          borderRadius="0"
          border="none"
          >See Results
        </Button>
      </div>
    ) } else if (questionState===15 & chartDisplay===true)
    return(
    <>
         <RaderChart 
            answerListChart={answerList}
          />
    </>
      )
    else if (transition===true){
        return (
      <ChakraProvider>
        <Box backgroundColor="#E3E7EB" height="100vh">
        <VStack spacing={20} alignItems="center"  marginBottom="80px">
          <Stack>
            <Text
              style={{
                paddingTop:["40px","60px"],
                textAlign: "left",
                lineHeight: "2",
                fontSize: ["12px","20px"],
                paddingLeft: ["20px","100px"],
                paddingRight: "100px",
                maxWidth: "100%",
                wordWrap: "break-word",
                marginTop:"50px",
                marginBottom:"20px"
              }}
            >
              {questions[questionState].question}
            </Text>
          </Stack>
          <Box>
            <Flex alignItems="center" justifyContent="center" h="25vh" marginTop="10px" marginBottom="80px">
              <Flex flexDirection="Column">
                {answers.map((option) => (
                  <OptionToggle
                    key={option.id}
                    option={option}
                    isSelected={option.id === selectedOption.id}
                    onClick={() => handleOptionSelect(option)}
                  />
                ))}
              </Flex>
            </Flex>
          </Box>
        </VStack>
        </Box>
      </ChakraProvider>
  )
      } else {
        if (questionState<=14){
        return(
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"30vh"}}>
          </div>
        )} 
      }
  }

export default LSM;