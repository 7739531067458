import { Box, Image, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useRef } from 'react';
import { Storage, Amplify } from 'aws-amplify';
import awsmobile from "../aws-exports";
import { useDisclosure } from "@chakra-ui/react"; // useNavigateの代わりにuseDisclosureを使用

Amplify.configure(awsmobile)
const MotionBox = motion(Box);

const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

function useIntersectionObserver() {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                console.log('Intersecting:', entry.isIntersecting);
            }, { threshold: 0 } 
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return [ref, isIntersecting];
}

function getFlexDirectionBasedOnPosition(imgPosition) {
    if (imgPosition === 'top') {
      return ['column', 'column']; // あるいは他のリスポンシブな値
    } else {
      return ['column', 'row']; 
    }
  }

  const IntroductionCard = ({imageUrl, altText, header, title,text1,text2, color1, color2, transit, imgPosition = 'top'}) => {
    console.log("c",color1)
    const [ref, visible] = useIntersectionObserver();
    const shouldShow = visible;
    const [isHovered, setIsHovered] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <AnimatePresence>
          <MotionBox
            alignItems="center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref = {ref}
            variants={cardVariants}
            initial="hidden"
            animate={shouldShow ? "visible" : "hidden"}
            exit="exit"
            w= {["100vw","100vw","300px", "400px"]}
            aspectRatio={["3/1","3/1","5/3","2/1"]} 
            mt={["50px","50px","100px", "100px"]}
            position={["relative","relative"]}
            display="flex"
            flexDirection={getFlexDirectionBasedOnPosition(imgPosition)} 
            justifyContent="center"
            boxShadow="custom"
            bg="#FFFFFF"
            cursor="pointer" 
            transition="box-shadow 0.2s ease"
            _hover={{
                boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.3), 15px 0px 20px rgba(0, 0, 0, 0.3)",
                "> Box > Image": {
                  transform: "scale(1.2)",
                }
             }}
             onClick={onOpen}
            >
          <Flex
            direction={["row","row","column","column"]}
            justifyContent={["flex-start","flex-start","center","center"]}
            alignItems={["center","center","center","center"]}
          >
            <Box 
                overflow="hidden"
                p={["10px","10px","30px","30px"]}
                mt={["20px","20px","30px","30px"]}
                mb={["10px","10px","10px","0px"]}
                ml={["","","0px","0px"]}
            >
                <Image
                    src={imageUrl}
                    alt={altText} 
                    boxSize={["140px","160px","180px", "250px"]}  
                    objectFit="cover"
                    borderRadius="full"
                    transform={isHovered ? 'scale(1.05)' : 'scale(1)'}
                    transition="transform 0.4s ease" // 画像のサイズ変化をアニメーション化します。
                />
            </Box>
            <Box>
            <Flex 
              direction="column" 
              ml={["30px","30px","0px","0px"]}
              mt={["60px","60px","20px","20px"]}
              justifyContent="center" 
              alignItems="center"
              >
                <Text
                    fontSize={["15px","20px"]}
                    fontWeight="bold"
                    position="center"
                    textAlign={["flex-start","flex-start","center","ceter"]}
                    w="100%"
                >
                {header}
                </Text>
                <Text
                    fontSize={["10px","15px"]}
                    position="center"
                    mt={["10px","10px","10px","10px"]}
                    textAlign={["flex-start","flex-start","center","ceter"]}
                    w="100%"
                >
                {title}
                </Text>
                <Text fontSize={["10px","15px"]} position="relative" ml={5} mt="30px" marginRight="20px" marginBottom="30px">
                        {text1}
                </Text>
            </Flex>
            </Box>
          </Flex>
        </MotionBox>
     </AnimatePresence>

    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Image
                src={imageUrl}
                alt={altText}
                borderRadius="50%"
                objectFit="cover" // 画像のアスペクト比を維持しつつコンテナに収める
                boxSize="250px" // 例として150pxのサイズを設定、必要に応じて調整
            />
            <Text marginTop="50px">
             {text2}
            </Text>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
    </ModalContent>
    </Modal>
    </>
  );
};

export default IntroductionCard;