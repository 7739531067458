import React, { useRef, useState } from 'react';
import { Box, Flex, Input, Textarea, Button, Text, Icon, Select, useToast } from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, AtSignIcon } from '@chakra-ui/icons';
import { Amplify,API, graphqlOperation } from 'aws-amplify'; // AmplifyのAPIとgraphqlOperationをインポート
import { createContactUs } from '../graphql/mutations'; // GraphQLミューテーションのインポート
import awsmobile from "../aws-exports";

Amplify.configure(awsmobile)

function ContactForm() {
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');

// ... (other states and imports remain unchanged)

    const toast = useToast();

    const handleSubmit = async () => {
        // Check if all required fields are filled out
        if (!title || !firstName || !lastName || !email || !companyName || !message) {
            toast({
                title: "Warning",
                description: "All fields are required.",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            return; // Prevent the submission if any field is empty
        }

        try {
            const input = {
                title,
                firstName,
                lastName,
                email,
                companyName,
                message
            };
            const result = await API.graphql(graphqlOperation(createContactUs, { input }));
            toast({
                title: "Success",
                description: "The data has been sent successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            console.log('Data saved:', result);
        } catch (error) {
            console.error('Error saving data:', error);
            toast({
                title: "Error",
                description: "An error occurred while sending data.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    return (
      <Flex width="100%" direction={["column","column","row","row"]} justifyContent="space-between" p={[5,5,10,10]} marginTop={["50px","50px","80px","80px"]}>
          <Box width={["100%","100%","68%","68%"]} m={[0,0,3,0]} bg="gray.100" p={[8,8,8,8]} borderRadius={5} mt={["5vh","5vh","2vh","0"]} mb={["3vh","5vh","10vh","10vh"]}>
              <Text fontFamily="'Open Sans', sans-serif" fontWeight="800" fontSize="xl" mb={[5,5,5,5]}>Send us a message</Text>
  
              <Select 
                  fontFamily="'Open Sans', sans-serif" 
                  placeholder="Title" 
                  width={["40%","25%"]} 
                  mb={[4,4]} 
                  mr={4}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
              >
                  <option value="mr">Mr.</option>
                  <option value="ms">Ms.</option>
                  <option value="dr">Dr.</option>
                  <option value="mx">Mx.</option>
              </Select>

              <Flex direction={["column","column","row","row"]}>

                <Input 
                    fontFamily="'Open Sans', sans-serif" 
                    placeholder="First Name" 
                    width={["100%","100%",,"100%","100%"]} 
                    mr={[4,4]}
                    mb={[4,4]} 
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
                <Input 
                    fontFamily="'Open Sans', sans-serif" 
                    placeholder="Last Name" 
                    idth={["100%","100%",,"100%","100%"]} 
                    mb={["10px","10px","10px","10px"]} 
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                />
              </Flex>

              <Input 
                  fontFamily="'Open Sans', sans-serif" 
                  placeholder="Your email" 
                  mb={4} 
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
              />
              <Input 
                  fontFamily="'Open Sans', sans-serif" 
                  placeholder="Company Name" 
                  mb={4}
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
              />
              <Textarea 
                  fontFamily="'Open Sans', sans-serif" 
                  placeholder="Write Your Message" 
                  mb={4}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
              />

            <Flex justifyContent="center" alignItems="center">
              <Button 
                  fontFamily="'Open Sans', sans-serif" 
                  color="white" 
                  bg="rgba(46,177,198,1)" 
                  onClick={handleSubmit}
              >
                  SEND MESSAGE
              </Button>
            </Flex>
          </Box>
  
          {/* 右側のコンタクト情報部分 */}
          <Box width={["100%","100%","30%","30%"]} bg="rgba(46,177,198,1)" color="white" p={[8,8,8,8]} borderRadius={5} mt={["3vh","5vh","2vh","0"]} mb={["15vh","15vh","10vh","10vh"]}>
              <Text fontFamily="'Open Sans', sans-serif" fontSize={["15px","20px"]} mb={5}>
                  Contact Information
              </Text>
              <Text mb={3} fontFamily="'Open Sans', sans-serif">
                  Please contact us using the form on the left, or you can reach out directly to the email below.
              </Text>
  
              <Flex alignItems="center" mb={["20px","40px"]}>
                  <Icon as={EmailIcon} mr={3} />
                  <Text fontFamily="'Open Sans', sans-serif">
                    <a href="mailto:info@lay-mindfulness.com" style={{ color: 'white' }}>
                        info@lay-mindfulness.com
                    </a>
                  </Text>
              </Flex>
  
              <Flex direction="column" alignItems="flex-start">
                  <Text 
                      fontFamily="'Open Sans', sans-serif" 
                      fontSize={["12px","18px"]} 
                      mb={["10px","20px"]} 
                      textAlign="left"
                  >
                      Lay Co.,Ltd.
                  </Text>
                  <Text 
                      fontFamily="'Open Sans', sans-serif" 
                      fontSize={["12px","15px"]}
                  >
                      Win Aoyama bldg. UCF917, 2-2-5, Minami-Aoyama, Minato-ku, Tokyo, 1070062, Japan
                  </Text>
              </Flex>
          </Box>
      </Flex>
  );
  
}

export default ContactForm;