import React from "react";
import { Flex, Box, Link,Button,Text,List,AspectRatio,OrderedList,VStack,Image, Divider, ListItem, UnorderedList} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import '../App.css';

const OneDayRetreat = ({
  videoUrl,
  videoSamnail,
  imageUrl_k1,
  imageUrl_k2,
  imageUrl_k3,
  imageUrl_k4,
  imageUrl_k5,
  imageUrl_k6,
  imageUrl_k7,
  imageUrl_k8,
  imageUrl_g1,
  imageUrl_g2,
  imageUrl_g3,
  imageUrl_g4,
  imageUrl_g5,
  imageUrl_g6,
  imageUrl_g7,
  imageUrl_g8
  }) => {

    const navigate = useNavigate();

  return (
    <>
    <Box display="flex" justifyContent="center">
     <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" position="relative" width="80%" marginTop="4rem" marginBottom="6rem">
     <Box id="program"></Box>
        <Box 
            p={["0","0"]}
            paddingTop={["30px","200px"]} 
            minHeight={["20vh","30vh","40vh","50vh"]}
            backgroundColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["120%","95%"]}
            position="relative"
        >
        <Box
            width="90%"
            height={["75px","150px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            marginTop={["-10px", "-150px"]} // この行を追加
        >
            <Flex direction="column" alignItems="center">
            <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Program 1.
                </Text>
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Reteat at Kamakura
                </Text>
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Yoga and Mindfulness
                </Text>
            </Flex>
        </Box>

        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="40px"
            >
        <Text width="90%" mt="10px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
             This retreat combines yoga and mindfulness to offer a refreshing and deeply introspective experience for both the mind and body. Hosting the retreat in a historical building with a garden in Kamakura provides a serene environment that enhances the experience. The timeless architecture and tranquil garden settings of Kamakura offer a unique backdrop that allows participants to connect with history, nature, and their inner selves more deeply.
        </Text>
        <Text fontWeight="bold" mt="20px" mb={2}>Objectives:</Text>
        <List styleType="disc" pl={5} mb={4}>
            <ListItem>Refreshment of Mind and Body: Experience the nature of Kamakura, taking a break from daily stress.</ListItem>
            <ListItem>Introspection: Engage deeply with oneself through the practice of mindfulness and yoga.</ListItem>
            <ListItem>Connection to Wholeness: Feel the connection to nature and others, experiencing the continuity and interdependence of life.</ListItem>
        </List>
        </Box>

        <Flex 
            width="90%" 
            mt="70px" // 上からのマージンを調整
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k1} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:00-10:30 Registration and Orientation
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Welcome drink and introduction to the flow of the retreat.</ListItem>
                <ListItem>Sharing of expectations and objectives.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k2} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:30-11:00 Mindfulness Theory Session
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Introduction to the basics of mindfulness.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k3} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                11:00-12:00 Yoga Session (Beginner Level)
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Practice basic asanas to soften the body.</ListItem>
                <ListItem>Incorporate breathing techniques to calm the mind.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k4} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                12:00-13:30 Japanese Lunch
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Traditional Japanese cuisine featuring seasonal ingredients.</ListItem>
                <ListItem>Maintain mindfulness during the meal.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k5} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                13:30-14:00 Tour of Traditional House and Garden
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Learn about the history of traditional Kamakura houses and gardens.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k6} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                14:00-15:00 Guided Mindfulness Meditation
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>A time for introspection, facing emotions and thoughts that reside within.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k7} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                15:00-15:45 Garden Stroll and Mindfulness Practice
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Practice mindfulness amidst nature.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_k8} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                15:45-16:30 Tea Time and Sharing Session
            </Text>
            <Text mt="20px" mb={2}>Objectives:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Japanese Maccha: Calm the mind with traditional tea.</ListItem>
                <ListItem>Sharing of Experiences: Share insights and feelings from the retreat, deepening the experience.</ListItem>
            </List>
            </Box>
        </Flex>

        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="20px"
            mb="20px"
            >
        <Text fontWeight="bold" mt={["0px","20px"]} mb={["10px","20px"]}>Notes:</Text>
        <List styleType="disc" pl={5} mb={["10px","20px"]}>
            <ListItem>No prior experience in yoga and/or mindfulness is required for this retreat. The content is designed for both beginners and experienced practitioners.</ListItem>
        </List>
        </Box>


      {/* ここから乗馬 */}
        <Box
            width="90%"
            height={["75px","150px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            marginTop={["50px", "-150px"]} // この行を追加
        >
            <Flex direction="column" alignItems="center">
            <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Program 2.
                </Text>
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    1-Day Mindfulness & Horseback Riding Retreat
                </Text>
            </Flex>
        </Box>

        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="40px"
            >
        <Text width="90%" mt="10px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        This one-day retreat is designed to focus on the often-forgotten “now” within the hustle and bustle of daily life, engaging with one’s inner self through experiences in nature with horseback riding and mindfulness meditation.
        No experience in horseback riding or mindfulness is necessary; the program is thoughtfully crafted to ensure all participants can relax and enjoy themselves.
        The retreat takes place at a horseback riding club in Gunma, with a view of the magnificent Mount Akagi and the flowing Tone River. Surrounded by beautiful nature, you will communicate with horses and take a mindful walk along the riverbank, focusing on the present moment with each deep breath.
        A vegan lunch prepared with local organic vegetables will also be provided, promising a special day where you can engage all five senses with nature.
        Objectives:
        </Text>
        <Text fontWeight="bold" mt="20px" mb={2}>Objectives:</Text>
        <List styleType="disc" pl={5} mb={4}>
            <ListItem>Practicing Mindfulness - Step away from the busy everyday life, focus on the present moment, and promote peace of mind and clear thinking.</ListItem>
            <ListItem>Reconnecting with Nature - Through horseback riding and forest walks, deepen your connection with nature and take the opportunity to refresh.</ListItem>
            <ListItem>Relaxing Body and Mind - Experience a state of relaxation by releasing physical and mental stress through meditation and horseback riding.</ListItem>
        </List>
        </Box>

        <Flex 
            width="90%" 
            mt="70px" // 上からのマージンを調整
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g1} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                 10:00 - 10:15
            </Text>
            <Text mt="20px" mb={2}>Registration & Welcome Drink:</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Upon arrival at the retreat location, guests are welcomed with herbal tea, detox water, and other drinks.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g2} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:15 - 10:45
            </Text>
            <Text mt="20px" mb={2}>Opening Session</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Explanation of the retreat’s purpose and schedule. A brief overview of what mindfulness is, its effects, and significance.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g3} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:45 - 11:45
            </Text>
            <Text mt="20px" mb={2}>Mindfulness Meditation</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Meditation sessions in nature, integrating deep breathing while attuned to the rustling of trees and the chirping of birds.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g4} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                11:45 - 13:15
            </Text>
            <Text mt="20px" mb={2}>Horseback Riding Experience</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Begin with the first meeting with the horse. Assist with brushing and caring for the horse to build communication. Then, under the guidance of a professional instructor, engage in the horseback riding experience, which can liberate the mind as you become one with nature.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g5} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                13:15 - 14:15
            </Text>
            <Text mt="20px" mb={2}>Lunch Time</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>A vegan lunch made with local organic vegetables. Eat mindfully, savoring each bite.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g6} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                14:15 - 14:45
            </Text>
            <Text mt="20px" mb={2}>Riverside Walk</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Walk slowly and mindfully, turning your attention to the sounds and scents of nature, practicing mindful walking.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g7} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                14:45 - 15:15
            </Text>
            <Text mt="20px" mb={2}>Experiential Workshop</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Learn how to incorporate mindfulness techniques into daily life and sharpen your senses.</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g8} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                15:15 - 16:30: Closing
            </Text>
            <Text mt="20px" mb={2}>Sharing Time</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>Participants share their experiences, feelings, and learnings from the day.</ListItem>
            </List>
            <Text mt="20px" mb={2}>Closing Meditation</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>A meditation to conclude the retreat, centered around gratitude.</ListItem>
            </List>
            </Box>
        </Flex>


        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="20px"
            mb="20px"
            >
        <Text fontWeight="bold" mt={["0px","20px"]} mb={["10px","20px"]}>Notes:</Text>
        <List styleType="disc" pl={5} mb={["10px","20px"]}>
            <ListItem>No prior experience in yoga and/or mindfulness is required for this retreat. The content is designed for both beginners and experienced practitioners.</ListItem>
        </List>
        </Box>

       

    </Box>
  </Box>
</Box>
</>
);
};

export default OneDayRetreat;