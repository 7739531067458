import { Chart, registerables } from "chart.js";
import { Radar } from 'react-chartjs-2';
import questionsData from "./Questions.json";
import feedbackData from "./Feedback.json";
import {useState, useEffect} from "react";
import {Box} from "@chakra-ui/react";

Chart.register(...registerables)

const RaderChart = (props) => {

  const classifications = ["Observing Sensations", "Not Reacting", "Not Judging", "Description and Articulation", "Conscious Action"];
  const [thisTimeScore, setThisTimeScore] = useState([]);
  const [scoreChanges, setScoreChanges] = useState([]);
  const [otherAveScore, setOtherAveScore] = useState([3,3,3,3,3])
  
    const reverseFunc =(questionsData, answers, i) =>{
      if (questionsData[i].reverse===true){
        return Math.abs(answers[i]-6);
      } else if (questionsData[i].reverse===false){
        return answers[i];
    }}

    const makeScore = (answers)=>{
        console.log(answers)
      const scores = [0,0,0,0,0]
      for (let i=0; i < answers.length; i++){
        if (questionsData[i].classification=="Observing Sensations"){
          scores[0] = scores[0]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="Not Reacting"){
          scores[1] = scores[1]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="Not Judging"){
          scores[2] = scores[2]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="Description and Articulation"){
          scores[3] = scores[3]+reverseFunc(questionsData, answers, i)
        } else if (questionsData[i].classification=="Conscious Action"){
          scores[4] = scores[4]+reverseFunc(questionsData, answers, i)
      }
    }
    const scores2 = scores.map(score=>Math.round(score/3*10)/10)
    return scores2
  }

  useEffect(() => {
    console.log((props.answerListChart))
    console.log(makeScore(props.answerListChart))
    setThisTimeScore(makeScore(props.answerListChart));
  }, [props.answerListChart]);
    
    const data = {
        labels: ["Observing Sensations", "Not Reacting", "Not Judging", "Description and Articulation", "Conscious Action"],
        datasets: [
          {
            label: 'Your Score',
            data: thisTimeScore,
            backgroundColor: 'rgba(35,166,186,0.2)',
            borderColor: 'rgba(35,166,186,1)',
            borderWidth: 1,
          },
          {
            label: 'average of others',
            data:otherAveScore,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
      }

    const getFeedback = (index, increment) => {
      let classificationObj;
      let direction;
      if (increment > 0) {
          // If the score has increased
          classificationObj = feedbackData.upScores[index];
          direction = "more";
      } else if (increment < 0) {
          // If the score has decreased
          classificationObj = feedbackData.downScores[index];
          direction = "less";
      }
      
      // check if change is significant (>= 0.6 or <= -0.6)
      if (classificationObj && Math.abs(increment) >= 0.6 && classificationObj.classification === classifications[index]) {
          return [classificationObj.classification,classificationObj.comment, direction];
      }
      return null;
  }
    
  const calculateChanges = (thisTimeScore, otherAveScore) => {
  const changes = [];
  for (let i = 0; i < thisTimeScore.length; i++) {
      if (otherAveScore.length > i) {
          const increment = thisTimeScore[i] - otherAveScore[i];
          const feedback = getFeedback(i, increment);
          if (feedback) {
              changes.push(feedback);
          }
      }
  }
    return changes; 
  }

  // スコアの変化を計算
  useEffect(() => {
    if (thisTimeScore.length === 5 && otherAveScore.length === 5) {
      setScoreChanges(calculateChanges(thisTimeScore, otherAveScore));
    }
  }, [thisTimeScore,otherAveScore]);
 
      return(
        <Box bg="white"  marginTop="60px"  marginBottom="90px" >
        <Box className='App'  margin="auto" width="600px" >
            <Radar 
                data = {data}
                options = {options}
                />
                {scoreChanges.length > 0 ? (
                  scoreChanges.map((change, index) => (
                      <p key={index} style={{marginBottom:"20px"}}>
                        "{change[0]}" is {change[2]} than the average of others.
                        {change[1]}
                          </p>
                      ))
                  ) : (
                  <p>{feedbackData.NoChange.comment}</p>
                  )}
         </Box>
         </Box>
      )
    }

export default RaderChart;