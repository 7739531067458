import React from "react";
import { Flex, Box, Link,Button,Text,List,AspectRatio,OrderedList,VStack,Image, Divider, ListItem, UnorderedList} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import '../App.css';

const CorporatePrograms = ({
  videoUrl,
  videoSamnail,
  imageUrl_p1,
  imageUrl_p2,
  imageUrl_p3,
  imageUrl_p4,
  imageUrl_p5,
  }) => {

    const navigate = useNavigate();

  return (
    <>
    <Box display="flex" justifyContent="center">
     <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" position="relative" width="80%" marginTop="4rem">
     <Box id="program"></Box>
     <Box width={["120%","95%"]} bg="white" display="flex" marginTop="5px" marginBottom="5px" height={["30px","40px"]} justifyContent="center" alignItems="center">
        <Flex justifyContent="space-between" alignItems="center" width="100%" p={["20px","80px"]}>
            <Link href="#program" fontSize={["12px", "16px"]}>Program</Link>
            <Link href="#features"  fontSize={["12px", "16px"]}>Features</Link>
            <Link href="#clients"  fontSize={["12px", "16px"]}>Clients</Link>
            <Link href="#contact"  fontSize={["12px", "16px"]}>Contact Us</Link>
        </Flex>
    </Box>
        <Box 
            p={["0","4"]}
            paddingTop={["30px","100px"]} 
            minHeight={["20vh","30vh","40vh","50vh"]}
            backgroundColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["120%","95%"]}
            position="relative"
        >
        <Box
            width="90%"
            height={["50px","80px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
        >
            <Flex direction="column" alignItems="center">
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Mindfulness 8-Week Program
                </Text>
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    (With Effect Measurement)
                </Text>
            </Flex>
        </Box>

    <Text width="94%" mt="50px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif' marginTop={["40px","80px"]}>
        Mindfulness reveals its effects and changes through continuous practice. However, maintaining this practice, especially in the midst of our busy lives and while trying to adopt a new habit, is by no means easy. At Lay, we offer an 8-week continuous program to support your mindfulness practice. Additionally, using our proprietary web application, we measure the degree of mindfulness before and after the program, visualizing its transformation.
    </Text>

    <Box 
      p={4} 
      backgroundColor="rgba(240,250,250,1)" 
      fontFamily="'Open Sans', sans-serif"
      fontSize={["12px","15px"]}
      width="94%"
      mt="40px"
    >
      <Text fontWeight="bold" mb={2}>Program Overview:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>This is a 4-session, 8-week program that combines the theory and practice of mindfulness.</ListItem>
        <ListItem>In the theoretical section, participants will first learn the foundational knowledge of mindfulness and then apply it to advance their understanding in stress management, communication skills, and the pursuit of well-being.</ListItem>
        <ListItem>In the practical section, various types of mindfulness meditation are introduced in each session, ensuring that every participant finds a meditation style that suits them.</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Expected Outcomes:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>A foundational understanding and practice of mindfulness, effective stress management and alleviation, enhanced communication skills, and an overall improvement in well-being.</ListItem>
        <ListItem>These not only enhance the quality of an individual's life but also boost workplace performance and team collaboration.</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Target Audience:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>All employees</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Format:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>Online</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Number of Sessions:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>4 sessions (held bi-weekly)</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Duration:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>60 minutes per session</ListItem>
      </List>
      <Text fontWeight="bold" mb={2}>Instructor:</Text>
      <List styleType="disc" pl={5} mb={4}>
        <ListItem>Rina Nomura, Founder of Lay Co.,Ltd.</ListItem>
      </List>
    </Box>

    <Box 
        p={4} 
        backgroundColor="rgba(240,250,250,1)" 
        fontFamily="'Open Sans', sans-serif"
        fontSize={["12px","15px"]}
        width="94%"
        mt="40px"
        >
        <Text fontWeight="bold" mb={2}>Course Flow:</Text>
        <List styleType="disc" pl={5} mb={4}>
            <ListItem>10 minutes: Introduction and Stretching</ListItem>
            <ListItem>5 minutes: Meditation Experience (everyone will share their sensations)</ListItem>
            <ListItem>10 minutes: Lecture (the theme changes each session)</ListItem>
            <ListItem>10 minutes: Meditation Practice</ListItem>
            <ListItem>10 minutes: Lecture</ListItem>
            <ListItem>10 minutes: Q&A</ListItem>
        </List>
    </Box>

    <Box 
        p={4} 
        backgroundColor="rgba(240,250,250,1)" 
        fontFamily="'Open Sans', sans-serif"
        fontSize={["12px","15px"]}
        width="94%"
        mt="40px"
        >
        <Text fontWeight="bold" mb={4}>
            Types of Meditation and Lecture Content for All 4 Sessions:
        </Text>
        
        <VStack spacing={5} align="start">
            {["1st", "2nd", "3rd", "4th"].map((session, index) => (
            <Box key={index} borderWidth="1px" borderRadius="md" p={3}>
                <Text fontWeight="bold">{session} Session</Text>
                <List styleType="disc" pl={5} spacing={2}>
                <ListItem><strong>Theme:</strong> {["Fundamentals of Mindfulness", "Stress and Mindfulness", "Communication and Mindfulness", "Well-being and Mindfulness"][index]}</ListItem>
                <ListItem><strong>Type of Meditation:</strong> {["Breath Meditation", "Observational Meditation", "Observational Meditation", "Compassion Meditation"][index]}</ListItem>
                <ListItem><strong>Lecture Content:</strong> {[
                    "Mindfulness is garnering attention worldwide, including in U.S. IT companies, as a method to maintain mental health and enhance productivity and creativity. We will learn about the meaning, background of its popularity, techniques, and what mindfulness truly entails.",
                    "Everyone experiences stress. While some stress can promote personal growth, other types can hinder motivation or cause physical and mental discomfort. It's essential to address and prevent this kind of stress. We will learn about the mechanisms of stress and ways to cope with and prevent it through the practice of mindfulness.",
                    "Communication is vital for business success and contributes to individual satisfaction and team achievements. However, challenges can arise from difficulties in self-expression and understanding others. By leveraging mindfulness, we will learn to deepen our understanding of ourselves and others and cultivate empathetic communication skills.",
                    "Well-being significantly impacts not only job performance but also the workplace atmosphere and individual happiness. A perspective to enhance the overall quality of one's life is essential. We will explore holistic well-being using mindfulness and learn tips for a better way of working and living."
                ][index]}</ListItem>
                </List>
            </Box>
            ))}
        </VStack>
    </Box>

    <Box 
    p={4} 
    backgroundColor="rgba(240,250,250,1)" 
    fontFamily="'Open Sans', sans-serif"
    fontSize={["12px","15px"]}
    width="94%"
    mt="40px"
    >
    <Text fontWeight="bold" mb={4}>
        Ancillary Service:
    </Text>
    
    <List styleType="disc" pl={5} spacing={2}>
        <ListItem>
        <strong>Effect Measurement:</strong> We measure the degree of mindfulness once before and once after the 8-week program, visualizing its transformation.
        </ListItem>
        <ListItem>
        <strong>Video:</strong> We provide meditation guide videos for daily meditation practice.
        </ListItem>
    </List>
    </Box>

    <Box 
        p={4} 
        backgroundColor="rgba(230,240,240,1)" 
        fontFamily="'Open Sans', sans-serif"
        fontSize={["14px","17px"]}
        width="96%"
        mt="40px"
        mb="40px"
        border="2px solid #000"
        borderRadius="5px"
    >
        <Text fontWeight="bold" mb={4} textAlign="center" textDecoration="underline">
            Price
        </Text>
        
        <List spacing={2} pl={5}>
            <ListItem>
            <strong>Up to 99 people:</strong> ¥398,000 (excluding tax)
            </ListItem>
            <ListItem>
            <strong>Up to 200 people:</strong> ¥448,000 (excluding tax)
            </ListItem>
            <ListItem>
            <strong>Up to 300 people:</strong> ¥498,000 (excluding tax)
            </ListItem>
            <ListItem>
            <strong>More than 300 people:</strong> Separate quote required
            </ListItem>
        </List>
    </Box>
    <Box id="features"></Box>

    <Box 
            width="90%"
            height={["50px","80px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            top="50px"
            mb={["40px","40px"]}
        >
            <Flex direction="column" alignItems="center">
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Features of Lay's Corporate Mindfulness Program
                </Text>
            </Flex>
    </Box>

    <Box 
      p={4} 
      backgroundColor="rgba(240,250,250,1)" 
      fontFamily="'Open Sans', sans-serif"
      fontSize={["12px","15px"]}
      width="90%"
      mt="40px"
      mb="40px"
    >
    <Text p={2} fontFamily='Open Sans, sans-serif'>
        We offer a unique program that integrates the knowledge of psychology and business acquired in graduate school with practical knowledge learned in India. This is backed by our track record of serving over 3,000 individuals as of January 2023.
      </Text>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="90%" mt="20px" mb="30px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="10px" mb="10px"fontSize={["11px","24px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Theory
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Image src={imageUrl_p3} alt="p3" mt="0px" width="90%" style={{ border: '0.1px solid #f5f5f5' }} mb={["40px","40px"]}/>
        <Text width="90%" mb="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        We provide explanations on the meaning, effects, and background of the proliferation of mindfulness, as well as lectures tailored to address the specific challenges faced by our client companies. Since business professionals often employ logical thinking in their daily tasks, understanding the theory before diving into practice tends to make them more receptive to its benefits.
        </Text>
        <Text width="90%" mb="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        We communicate the significance of mindfulness in terms of the body, thoughts, and emotions, and the effects one can expect from its practice. Additionally, we explain 'Why does mindfulness lead to business benefits such as stress reduction, enhanced creativity, and improved communication?'
        </Text>
        <Text width="90%" mb="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        By deeply understanding these theories, participants can more tangibly experience the benefits and ensure a consistent and genuine practice.
        </Text>
        <Text width="90%" mb="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Furthermore, by theoretically grasping the sensations and emotions that arise within oneself, individuals can discern which mindfulness techniques best suit them. Upon request, our company also offers brainwave measurement and analysis using Muse.
        (You can learn more at <a href="https://choosemuse.com" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', color: 'rgba(46,177,198,1)', textDecoration: 'none' }}>https://choosemuse.com</a>).
        </Text>

    <Box 
        width="90%" 
        minHeight={["100px","150px"]}
        p="8px"
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
    >
        <Flex direction="column" ml={["5px","20px"]}>
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
                Feedback from Lay Program Participants
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    The balance between theoretical explanation and hands-on experience was excellent, making it enjoyable for beginners to study mindfulness.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    The content was logically organized and easy to understand.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    Being introduced to the theory first made me feel at ease when diving into the practice. I also appreciated learning about 'What is mindfulness?' It made me realize that I might be overthinking things in my daily life.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    The instructor always spoke with a smile, making the content even more accessible."
                </ListItem>
            </UnorderedList>
        </Flex>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="90%" mt="70px" mb="30px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="10px" mb="10px"fontSize={["11px","24px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Mindfulness Meditation
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="90%" mb="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        We've made various adjustments to ensure even beginners can immediately put into practice what they learn.
    </Text>

    <Image src={imageUrl_p5} alt="p5" mt="0px" width="90%" style={{ border: '0.1px solid #f5f5f5' }} mb={["40px","40px"]}/>
    
    <Flex direction="column" ml={["5px","20px"]} width="90%">
        <UnorderedList spacing={2} fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
            <ListItem fontSize={["12px","15px"]}>
                Activities are designed to be done in office environments like meeting rooms or break rooms, where participants can remain seated in chairs.
            </ListItem>
            <ListItem fontSize={["12px","15px"]}>
                With the instructor's guidance, participants are carefully and sequentially guided on how to engage with their breath, thoughts, and emotions for each activity.
            </ListItem>
            <ListItem fontSize={["12px","15px"]}>
                Various breathing meditation techniques are chosen based on the participants' objectives, and they can be selected in any order from session 1 to 4. For instance, in high-stress workplaces, observational meditation is recommended. In environments where creativity is paramount, journaling meditation is suggested. And in workplaces where interpersonal relationships are crucial, compassion meditation is introduced."
            </ListItem>
        </UnorderedList>
    </Flex>

    <Box 
        width="90%" 
        minHeight={["100px","150px"]}
        p="8px"
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        mt={["40px","40px"]}
    >
    <Flex direction="column" ml={["5px","20px"]}>
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
                Feedback from Lay Program Participants
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    The sense of accomplishment and experience from completing a 20-minute meditation was truly remarkable. 
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                Until now, I've been practicing mindfulness meditation for short durations daily, but I'm considering extending the time moving forward. After stretching and then meditating, I felt I could maintain a comfortable state of mind.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                The guidance on what to do during the breathing meditation was specific and helpful. 
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                I'm thinking of meditating during lunch breaks, perhaps as an alternative to taking a nap.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                I've realized that practicing mindfulness is especially beneficial when I'm overwhelmed by certain thoughts; it helps me feel centered and better equipped to face challenges.
                </ListItem>
            </UnorderedList>
        </Flex>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="90%" mt="70px" mb="30px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="10px" mb="10px"fontSize={["11px","24px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Yoga Stretch
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="90%" mb="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        By doing Yoga Stretch before meditation, we loosen up the body that has become stiff from work. As the body relaxes, breathing becomes smoother and posture improves. Additionally, with improved blood circulation, the meditation experience becomes more profound.
    </Text>

    <Image src={imageUrl_p4} alt="p4" mt="0px" width="90%" style={{ border: '0.1px solid #f5f5f5' }} mb={["40px","40px"]}/>

    <Box 
        width="90%" 
        minHeight={["100px","150px"]}
        p="8px"
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
    >
    <Flex direction="column" ml={["5px","20px"]}>
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
                Feedback from Lay Program Participants
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    I felt that stretching is effective not only for calming the mind but also for loosening a stiff body (it's refreshing).
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    With the preparatory exercises and the stretching, my body felt much more relaxed, and I believe issues like shoulder stiffness have improved.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    I appreciated the clear explanations on stretches and mood-changing techniques that can be done at home.
                </ListItem>
                <ListItem fontSize={["10px","12px"]} fontStyle="italic">
                    I learned that you can easily stretch while seated. As the instructor mentioned, after moving the body a bit with stretching and then meditating, both my mind and body felt more refreshed. I'd like to incorporate this during break times and share it with others around me. Thank you!
                </ListItem>
            </UnorderedList>
        </Flex>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="90%" mt="70px" mb="30px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="10px" mb="10px"fontSize={["11px","24px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Effect Measurement
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Flex direction="column" ml={["5px","20px"]} width="90%">
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px" width="100%">
                We conduct surveys to assess the effects of mindfulness both before and after the program begins.
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["12px","15px"]}>
                    The survey consists of 15 questions, with 3 questions in each of the 5 areas we've uniquely developed to measure the effects of mindfulness.
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    As the survey is conducted on our proprietary web application, results can be viewed immediately after measurement.
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Participants can compare and see the difference in their mindfulness levels before and after the program.
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                   While most departments conducting the survey are HR, you can grasp the average score of all participants and understand variations by department.
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Please rest assured that when using the aforementioned web application, we do not collect personal information such as names or email addresses. Clients distribute unique IDs for individual identification.
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    As an option, we offer comparison reports against our accumulated database."
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Furthermore, we also offer brainwave measurements using devices from Muse, accompanied by our unique analysis.
                </ListItem>
            </UnorderedList>
        </Flex>

    <Box 
        width="90%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        mt="60px"
        border="0.2px solid #d3d3d3" 
        mb="20px"
    >
        <Flex direction="column" alignItems="center">
            <Text color="#151515" fontSize={["10px","15px"]} fontFamily='Open Sans, sans-serif'>
                Effect Measurement Results Sample 
            </Text>
            <Text color="#151515" fontSize={["10px","15px"]} fontFamily='Open Sans, sans-serif'>
                (For Participants)
            </Text>
        </Flex>
      </Box>
        <Image src={imageUrl_p1} alt="p1" mt="0px" width="90%" style={{ border: '0.1px solid #f5f5f5' }} mb={["40px","40px"]}/>
    
        <Box 
        width="90%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        border="0.2px solid #d3d3d3" 
        mb="30px"
    >
        <Flex direction="column" alignItems="center">
            <Text color="#151515" fontSize={["10px","15px"]} fontFamily='Open Sans, sans-serif'>
                Effect Measurement Results Sample 
            </Text>
            <Text color="#151515" fontSize={["10px","15px"]} fontFamily='Open Sans, sans-serif'>
                (For HR Team)
            </Text>
        </Flex>
      </Box>
        <Image src={imageUrl_p2} alt="p2" mt="0px" width="90%" style={{ border: '0.1px solid #f5f5f5' }} />
        <Box id="clients"></Box>

        <Box 
            width="90%"
            height={["50px","50px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            top="50px"
            mb={["70px","70px"]}
        >
            <Flex direction="column" alignItems="center">
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Clients
                </Text>
            </Flex>
        </Box>

        <Flex direction="column" ml={["5px","20px"]} width="90%">
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
                Business Cliants
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} ml={["10px","30px"]}fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["12px","15px"]}>
                Major telecommunications company (Japan headquarters)
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                Major pharmaceutical company (U.S. headquarters, Japanese subsidiary)
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                Leading asset management company
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                Mid-sized energy-related company (Japan headquarters)
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                Mid-sized real estate-related company (Japan headquarters)
                </ListItem>
            </UnorderedList>
        </Flex>

        <Flex direction="column" ml={["5px","20px"]} width="90%" mt={["40px","40px"]}>
            <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
                Educational Institutions 
            </Text>
            <UnorderedList spacing={2}fontSize={["12px","15px"]} ml={["10px","30px"]}  fontFamily='Open Sans, sans-serif'> 
                <ListItem fontSize={["12px","15px"]}>
                    Keio University
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Gakushuin University
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Globis Business School
                </ListItem>
                <ListItem fontSize={["12px","15px"]}>
                    Iwasaki Academy (Leading Vocational School in Japan)
                </ListItem>
            </UnorderedList>
        </Flex>

    <Box id="contact"></Box>
    <Box 
            width="90%"
            height={["50px","50px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            mt={["50px","50px"]}
            mb={["40px","40px"]}
        >
            <Flex direction="column" alignItems="center">
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    Process to Implementation:
                </Text>
            </Flex>
        </Box>

    <Box 
        width="90%" 
        minHeight={["100px","150px"]}
        p="4px"
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        mb={["80px","120px"]}
    >
    <Flex direction="column" ml={["5px","5px"]} width="90%" mt={["40px","40px"]}>
        <OrderedList spacing={2} fontSize={["12px","15px"]} ml={["10px","30px"]}  fontFamily='Open Sans, sans-serif'> 
            <ListItem fontSize={["12px","15px"]}>
                Inquiry
                <Text fontSize={["10px","13px"]} mt="5px">
                    First, please contact us using the form provided. Don't worry if you lack knowledge or experience in mindfulness; feel free to consult with us.
                </Text>
                <Text fontSize={["10px","13px"]} mt="5px">
                    Currently, for HR personnel considering implementation, we offer a 15-minute individual trial session that covers both the theory and practice of mindfulness. It's not uncommon for those in charge to be unfamiliar with mindfulness. Experiencing it firsthand can deepen your understanding and aid in your decision-making process. If interested, please indicate so in the "Additional Comments" section of the inquiry form.
                </Text>
                <Box display="flex" justifyContent="center">
                    <Button 
                        mt={["15px","20px"]}
                        mb={["15px","40px"]}
                        color="white" 
                        bg="rgba(46,177,198,1)" 
                        onClick={() => navigate("/contact-us")} 
                        height={["25px","30px"]}
                        width={["80px","100px"]}
                        padding={["5px 10px","5px 10px"]}
                        fontSize={["12px","15px"]}
                    >
                        Contact Us
                    </Button>
                </Box>
            </ListItem>
            <ListItem fontSize={["12px","15px"]}>
                    Consultation
                <Text fontSize={["10px","13px"]} mb="15px">
                    We will conduct an online hearing to understand the challenges you face, the kind of organization you aim to build, and the objectives for introducing the program. We can introduce training plans and case studies, and will carefully address any questions or concerns you might have.
                </Text>
                <Text fontSize={["10px","13px"]} mb="15px">
                    Currently, for HR personnel considering implementation, we offer a 15-minute individual trial session that covers both the theory and practice of mindfulness. It's not uncommon for those in charge to be unfamiliar with mindfulness. Experiencing it firsthand can deepen your understanding and aid in your decision-making process. If interested, please indicate so in the "Additional Comments" section of the inquiry form.
                </Text>
            </ListItem>
            <ListItem fontSize={["12px","15px"]}>
                Mindfulness Program Proposal
                <Text fontSize={["10px","13px"]} mb="15px">
                    Based on the hearing, we will propose the most suitable training program for your needs.
                </Text>
            </ListItem>
            <ListItem fontSize={["12px","15px"]}>
                Implementation Start 
                <Text fontSize={["10px","13px"]} mb="15px">
                    We will conduct mindfulness training that reflects the content discussed during the hearing.
                </Text>
            </ListItem>
        </OrderedList>
        </Flex>
    </Box>

    </Box>
  </Box>
</Box>
</>
);
};

export default CorporatePrograms;

