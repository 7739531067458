import { Box, Image, Flex,Text } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from "../aws-exports";
import { useNavigate } from 'react-router-dom';

Amplify.configure(awsmobile)
const MotionBox = motion(Box);

const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

function useIntersectionObserver() {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                console.log('Intersecting:', entry.isIntersecting);
            }, { threshold: 0 } 
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return [ref, isIntersecting];
}

const PopUpCard = ({
        imageUrl, 
        altText, 
        header, 
        text,
        color1,
        color2,
        transit,
        flexDirection,
        imageWidth,
        imageHeight,
        imageMarginLeft,
        imageMarginTop,
        textAlign,
        textMarginTop,
        textMarginBottom,
        text1Width,
        text2Width,
        text1FontSize,
        text2FontSize,
        cardSizeHeight,
        cardSizeWidth,
        cardMarginTop,
        cardMarginBottom,
        cardMarginLeft,
        cardMarginRight,
        cardPadding,
    }) => {

    console.log("c",color1)
    const [ref, visible] = useIntersectionObserver();
    const shouldShow = visible;
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

  return (
    <AnimatePresence>
          <MotionBox
            alignItems="center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref = {ref}
            variants={cardVariants}
            borderRadius="30px"
            initial="hidden"
            animate={shouldShow ? "visible" : "hidden"}
            exit="exit"
            mx="auto" // 真ん中に持ってくるChakraUIの記法
            h={cardSizeHeight}
            w={cardSizeWidth}
            p={cardPadding}
            mt={cardMarginTop}
            mb={cardMarginBottom}
            ml={cardMarginLeft}
            mr={cardMarginRight}
            position={["relative","relative"]}
            display="flex"
            flexDirection={flexDirection}
            boxShadow="custom"
            bg={`linear-gradient(to right,${color1}, ${color2})`}
            cursor="pointer"
            transition="box-shadow 0.2s ease"
            _hover={{
                boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.3), 15px 0px 20px rgba(0, 0, 0, 0.3)",
                "> Box > Image": {
                  transform: "scale(1.2)",
                }
             }}
             onClick={() => navigate(transit)}
            >
            <Box 
                overflow="hidden"
                mt = {["20px","0px"]}
                ml = {imageMarginLeft}
                mx = "auto"
            >
                <Image
                    src={imageUrl}
                    alt={altText} 
                    w = {imageWidth}
                    h = {imageHeight}
                    mt = {imageMarginTop}
                    objectFit="contain"
                    borderRadius="10px"
                    transform={isHovered ? 'scale(1.05)' : 'scale(1)'}
                    transition="transform 0.4s ease" // 画像のサイズ変化をアニメーション化します。
                />
            </Box>

            <Flex direction="column" alignItems={textAlign} ml="10px" mr="30px" mt={textMarginTop} mb={textMarginBottom}>
                <Text
                    fontSize={text1FontSize}
                    fontWeight="bold"
                    position="relative"
                    fontFamily='Open Sans, sans-serif'
                    width={text1Width}
                >
                {header}
                </Text>
                <Text
                    fontSize={text2FontSize}
                    fontFamily='Open Sans, sans-serif'
                    position="relative"
                    mt="20px"
                    width={text2Width}
                >
                {text}
                </Text>
            </Flex>
        </MotionBox>
     </AnimatePresence>
  );
};

export default PopUpCard;
