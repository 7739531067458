// TopPage.js
import React from 'react';
import { Box, Image, Flex,Text } from '@chakra-ui/react';
import PopUpCard from "./PopUpCard";
import { Global,css } from "@emotion/react";
import { useWindowSize } from '../WindowSize';

function Home({ 
      desktopVideo, 
      imageUrl_t1,  
      imageUrl_t2,  
      imageUrl_t3,  
      imageUrl_t4,  
      mobileImage,
    }) {

    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 480;
    const isPC = windowSize.width <= 480;

  return (
    <>
      <Global
        styles={css`
          @keyframes slideIn {
            0% {
              right: -100%; // Start from the right
            }
            100% {
              right: calc(50%);
              transform: translateX(50%); // Center the text horizontally
            }
          }
        `}
      />
      {/* 最上段 */}
      <Flex direction="column" bg="#e2e2e2" display="flex" alignItems="center" >
        <Box
          w="100%"
          mt={["","8vh","8vh","7vh"]}
          h={["40vh","50vh","60vh","85vh"]}
          position="relative" 
          overflow="hidden"
          marginBottom="30px"
          fontFamily="'Open Sans', sans-serif"
        >
        {isPC ? (
          //    <Image
          //    width="100%"
          //    height="auto"
          //    src={mobileImage}
          //   //  h={["50vh","80vh"]}
          //    backgroundColor="#031b3b"
          //    position="relative"
          //    mt={["60px","57px"]}
          //  />
          <Box marginTop="9vh">
          <video
            width="80%"
            height="auto"
            marginTop="100px"
            src={desktopVideo}
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center"
              }}
            type="video/mov"
            autoPlay
            loop
            muted
            playsinline/>
          </Box>
         ) : (
          <video
            width="100%"
            height="auto"
            src={desktopVideo}
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center"
              }}
            type="video/mov"
            autoPlay
            loop
            muted
            playsinline/>
            )}
        </Box>

        <Box mt={["20px","70px"]}>
          <PopUpCard
            imageUrl={imageUrl_t1}
            style={{
                backgroundImage: `url(${imageUrl_t1})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
            }}
            altText="Why Mindfulness"
            header="Why Mindfulness"
            text = "Mindfulness involves being fully present in the moment without judgment, observing our thoughts and feelings with curiosity. Originating from ancient Buddhist teachings, it aligns with Zen principles from Mahayana Buddhism, which emphasize meditation and living in the present. In today's world, both Zen and mindfulness provide tools to navigate challenges with clarity and calmness."
            color1="#cacacd"
            color2="#cacacd"
            transit="/whyMindfulness"
            justifyContent="center"
            display="flex"
            flexDirection={["column","column","column","row"]}
            imageWidth={["100%","105%","80%","80%"]}
            imageHeight={["100%","105%","80%","80%"]}
            imageMarginLeft={["20px","20px","","80px"]}
            imageMarginTop={["10px","20px","0","0"]}
            cardSizeHeight={["68vh", "80vh", "65vh", "40vh"]}
            cardSizeWidth={["85%", "80%", "80%", "90%"]}
            cardMarginTop={["15px","0px"]}
            cardMarginBottom=""
            cardMarginLeft={["","","",""]}
            cardMarginRight={["","","",""]}
            cardPadding={["","5","",""]}
            textAlign={["center","flex-start"]}
            textMarginTop={["25px","","-100px","0px"]}
            textMarginBottom={["10px","0px","0px","0px"]}
            text1Width={["",""]}
            text2Width={["80%","100%"]}
            text1FontSize={["15px","22px"]}
            text2FontSize={["12px","15px"]}
          />
        </Box>
      
        <Flex direction={["column","column","column","row"]} justifyContent="center" alignItems="center" mt={["20px","80px"]} mb={["20px","50px"]}>
            <PopUpCard
              imageUrl={imageUrl_t2}
              altText="Corporate Mindfulness Programs"
              header="Corporate Mindfulness Programs"
              text = "Corporate Mindfulness Programs are designed for businesses and organizations to promote mental well-being among employees. These programs aim to reduce workplace stress, enhance concentration, and boost productivity by introducing mindfulness practices into the daily routine."
              color1="#cacacd"
              color2="#cacacd"
              justifyContent="center"
              display="flex"
              transit="/corporatePrograms"
              flexDirection={["column","column","column","column"]}
              imageWidth={["93%","100%","80%","100%"]}
              imageHeight={["93%","100%","80%","100%"]}
              imageMarginLeft={["20px","20px","","10px"]}
              imageMarginTop={["10px","20px","0","30px"]}
              cardSizeHeight={["68vh", "80vh", "65vw", "70vh"]}
              cardSizeWidth={["85%", "80%", "80%", "90%"]}
              cardMarginTop={["15px","","","60px"]}
              cardMarginBottom={["20px","50px","50px","30px"]}
              cardMarginLeft={["","","","50px"]}
              cardMarginRight={["","","","50px"]}
              cardPadding={["0px","5","","15px"]}
              textAlign={["center","flex-start","flex-start","center"]}
              textMarginTop={["25px","","-100px","30px"]}
              textMarginBottom={["10px","10px"]}
              text1Width={["","","","80%"]}
              text2Width={["80%","","","80%"]}
              text1FontSize={["15px","22px"]}
              text2FontSize={["12px","15px"]}
              />
            <Box w="70px"/>
            <PopUpCard
              imageUrl={imageUrl_t3}
              altText="Mindfulness Retreats"
              header="1-day Mindfulness Retreats"
              text = "Mindfulness Retreats are immersive experiences where individuals can deeply engage with mindfulness and meditation practices. Set in tranquil environments away from urban distractions, these retreats offer a dedicated space for personal growth, reflection, and rejuvenation."
              color1="#cacacd"
              color2="#cacacd"
              transit="/1dayRetreat"
              justifyContent="center"
              display="flex"
              flexDirection={["column","column","column","column"]}
              imageWidth={["93%","100%","80%","100%"]}
              imageHeight={["93%","100%","80%","100%"]}
              imageMarginLeft={["20px","20px","","10px"]}
              imageMarginTop={["10px","20px","0","30px"]}
              cardSizeHeight={["68vh", "80vh", "65vw", "70vh"]}
              cardSizeWidth={["85%", "80%", "80%", "90%"]}
              cardMarginTop={["15px","50px","50px","60px"]}
              cardMarginBottom={["20px","0px","","30px"]}
              cardMarginLeft={["","","","50px"]}
              cardMarginRight={["","","","50px"]}
              cardPadding={["0px","5","","15px"]}
              textAlign={["center","flex-start","flex-start","center"]}
              textMarginTop={["25px","","-100px","30px"]}
              textMarginBottom={["10px","10px"]}
              text1Width={["","","","80%"]}
              text2Width={["80%","","","80%"]}
              text1FontSize={["15px","22px"]}
              text2FontSize={["12px","15px"]}
            />
        </Flex>

        <Box mt={["20px","50px"]} mb={["100px","100px"]}>
                  <PopUpCard
                    imageUrl={imageUrl_t4}
                    altText="Who are We"
                    header="Who are We?"
                    text ="Lay was founded by Rina. Drawing from her extensive business experience, she recognized the importance of mental stability for professionals. Leaving her corporate role, she ventured to India, renowned for yoga, to deepen her understanding. Alongside her training in behavioral psychology, she established Lay. She's now advancing her studies in Buddhism and depth psychology, refining Lay's programs."
                    color1="#cacacd"
                    color2="#cacacd"
                    transit="/whoWeAre"
                    flexDirection={["column","column","column","row"]}
                    imageWidth={["100%","100%","80%","80%"]}
                    imageHeight={["100%","100%","80%","80%"]}
                    imageMarginLeft={["20px","20px","","80px"]}
                    imageMarginTop={["10px","20px","0","0"]}
                    cardSizeHeight={["68vh", "80vh", "65vw", "40vh"]}
                    cardSizeWidth={["85%", "80%", "80%", "90%"]}
                    cardMarginTop={["15px","0px","10px","0px"]}
                    cardMarginBottom=""
                    cardMarginLeft=""
                    cardMarginRight=""
                    cardPadding={["","5","",""]}
                    textAlign={["center","flex-start"]}
                    textMarginTop={["25px","","-100px","0px"]}
                    textMarginBottom={["10px","0px"]}
                    text1Width={["",""]}
                    text2Width={["80%","100%"]}
                    text1FontSize={["15px","22px"]}
                    text2FontSize={["12px","15px"]}
                  /> 
        </Box>
      </Flex>
    </>
  );
}

export default Home;
