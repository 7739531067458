
import React from "react";
import { Flex, Box, Text,AspectRatio,VStack,Image, Divider, ListItem, UnorderedList} from "@chakra-ui/react";

const WhyMindfulessPage = ({
  videoUrl,
  videoSamnail,
  imageUrl_w1,
  imageUrl_w2,
  imageUrl_w3,
  imageUrl_w4,
  imageUrl_w5,
  imageUrl_w6,
  }) => {

  return (
    <>
    <Box display="flex" justifyContent="center">
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" position="relative" width="80%" marginTop="4rem">
    <Box 
    p={["0","4"]}
    paddingTop={["70px","100px"]} 
    minHeight={["20vh","30vh","40vh","50vh"]}
    backgroundColor="white"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width={["120%","95%"]}
    position="relative"
>
    <Box 
        width="90%"
        height={["40px","60px"]} 
        backgroundColor="rgba(46,177,198,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="absolute"
        top="50px"
    >
        <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
            What is Mindfulness?
        </Text>
    </Box>
    <Image src={imageUrl_w1} alt="w1" mt="50px" width="94%" />

    <Text width="94%" mt="50px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Mindfulness refers to the mental state of observing "the here and now" just as it is. In the business world, especially in the West, the effectiveness of mindfulness is widely recognized, and it has been adopted even by major corporations. While the focus has previously been on the benefits it brings to individuals, the advantages of incorporating mindfulness at the corporate or team level are now gaining attention.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["11px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                We usually don't observe "the here and now" just as it is.
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        In the midst of overwhelming information, we often unconsciously engage in other thoughts. According to research, people spend about 47% of their waking hours thinking about something other than what they're currently doing. In other words, many operate on "autopilot," a state often referred to as "mindlessness." During such mindless moments, we tend not to perceive events as they truly are but rather think based on past experiences, suppressing or escaping negative emotions like anger or sadness, or even act impulsively driven by these emotions.
    </Text>

    <Box 
        width="95%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="left"
        position="relative"
        mt="40px"
        border="0.2px solid #d3d3d3" 
    >
        <Text color="#151515" ml="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
            Consequences of a Mindless State
        </Text>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="30px">
        <UnorderedList spacing={2} fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
            <ListItem >Chronic stress and burnout</ListItem>
            <ListItem>Inability to deliberately choose one's focus and actions, leading to reflexive behaviors inappropriate for the situation or becoming inflexible</ListItem>
            <ListItem>Feeling down or depressed</ListItem>
            <ListItem>Accumulation of anger, becoming aggressive, or exhibiting passive-aggressive behavior</ListItem>
        </UnorderedList>
    </Box>

    <Box 
        width="95%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="left"
        position="relative"
        mt="20px"
        border="0.2px solid #d3d3d3" 
    >
        <Text color="#151515" ml="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
            Changes that Occur in a Mindful State
        </Text>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="30px">
        <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
            <ListItem>Ability to protect oneself from harmful stress</ListItem>
            <ListItem>Enhanced ability to deliberately choose one's focus and actions, suppressing reflexive behaviors that are inappropriate for the situation, and exhibiting the "self-control" capacity to adapt flexibly. It also improves the ability to make "optimal decisions" based on learnings from past experiences.</ListItem>
            <ListItem>Achieving a stable and calm mood</ListItem>
            <ListItem>Reduced likelihood of interpreting certain inputs as threats and a decrease in aggressiveness.</ListItem>
        </UnorderedList>
    </Box>

    <Box 
        width="90%" 
        height={["40px","60px"]} 
        backgroundColor="rgba(46,177,198,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        top="50px"
        mb="50px"
    >
        <Text color="white" fontSize={["12px","25px"]} fontFamily='Open Sans, sans-serif' fontWeight="800">
            Techniques for Achieving Mindfulness
        </Text>
    </Box>

    <Text width="94%" mt="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Mindfulness and mindfulness meditation are often conflated, but mindfulness meditation is just one of the techniques to achieve mindfulness. To attain a state of mindfulness, there are countless methods, aside from meditation, that can be incorporated into daily life. For example, one can consciously direct their attention to "the here and now."
    </Text>

    <Image src={imageUrl_w2} alt="w2" mt="50px" width="94%" />

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Mindfulness Meditation
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Box 
        width="95%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="left"
        position="relative"
        mt="20px"
        border="0.2px solid #d3d3d3" 
    >
        <Text color="#151515" ml="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
            Prominent Mindfulness Meditation
        </Text>
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        While there are various types of mindfulness meditation, one of the most representative is breath meditation. You focus on your breathing, and when thoughts arise, you notice that you are thinking (without chasing after the thought or judging it as good or bad) and then return your attention to your breathing. (It's natural for thoughts to emerge, and there's no need to empty the mind.)
    </Text>

    <Box 
        width="95%" 
        height={["30px","40px"]} 
        backgroundColor="#f5f5f5" 
        display="flex" 
        alignItems="center" 
        justifyContent="left"
        position="relative"
        mt="20px"
        border="0.2px solid #d3d3d3" 
    >
        <Text color="#151515" ml="20px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
            Changes that Occur in a Mindful State
        </Text>
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Recent research suggests that by directing consciousness to "this very moment" without subjective judgments (i.e., practicing mindfulness), there are observable changes in the brain. Mindfulness meditation is a method to maintain a healthy brain, protect oneself from harmful stress, and support self-control and decision-making abilities.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Techniques Beyond Meditation
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        A mindful state is achieved through two skills: focus and awareness. "Focus" is the ability to devote oneself entirely to what one is doing at the moment, while "awareness" pertains to recognizing the state of one's consciousness and the ability to discard distractions. Mindfulness isn't just about sitting in meditation; it's about "sharpening and clarifying consciousness." Hence, countless techniques beyond meditation can be applied in everyday life. For instance, "working mindfully" means that from the moment you arrive at the office, you employ both focus and awareness in everything you do. Concentrating on the task at hand and recognizing and discarding any internal or external distractions boosts performance, reduces mistakes, and enhances creativity.
    </Text>

    <Box 
        width="90%" 
        height={["40px","60px"]} 
        backgroundColor="rgba(46,177,198,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        top="50px"
        mb="50px"
    >
        <Text color="white" fontSize={["12px","25px"]} fontFamily='Open Sans, sans-serif' fontWeight="800">
            Benefits of Mindfulness in Business
        </Text>
    </Box>

    <Image src={imageUrl_w3} alt="w3" mt="50px" width="90%" />

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        In a complex and rapidly changing era, businesses must confront uncertainty and create new value. Unlike before, it's becoming challenging to generate value merely by employees faithfully following top-down directives within existing structures. Thus, enhancing the creativity of each individual employee has become crucial. Several factors hinder the enhancement of individual creativity. Among the most influential, and also high in demand within Lay's program, are "workplace stress" and the "decline in active workplace communication." Here, we'll introduce the benefits of mindfulness concerning these issues.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Addressing Workplace Stress
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Various stresses exist in the workplace. Of course, not all stress is negative. In the process of personal development, some stress can serve as a positive stimulus, enhancing learning and promoting personal growth.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        On the other hand, stress that arises from overextending oneself in a threatening manner, acting excessively against one's will, needs to be managed and prevented. If left unaddressed, it can lead to chronic stress or burnout. While burnout is often regarded as an individual-level issue in Japan, it is officially recognized by the World Health Organization (WHO). Additionally, the economic and emotional costs of burnout are significant. As a result, the responsibility for addressing it is shifting from individuals to organizations and companies.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Classification of Workplace Stress
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Classification of Workplace Stress and the Aspects Influenced by the Practice of Mindfulness (See Figure 1: Workplace Stress and Mindfulness)
    </Text>

    <Image src={imageUrl_w4} alt="w4" mt="50px" width="94%" />

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
         In the workplace, there are two main types of stress: stress that is primarily self-generated, influenced by one's own interpretations and perceptions, and stress that arises primarily from external factors, such as the work environment.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        The stress that is primarily self-generated includes stress from the suppression or avoidance of emotions and stress from automatic thinking. On the other hand, the stress that arises primarily from external factors encompasses aspects like job workload, compensation, and organizational culture.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Among these two types of stress, the ones that can potentially be alleviated through the practice of mindfulness are the stress from the suppression or avoidance of emotions and stress from automatic thinking. We will delve deeper into these aspects in the following sections.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Workplace Stress Potentially Alleviated by the Practice of Mindfulness
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        1. Stress Arising from Emotional Suppression and Avoidance.
    </Text>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        In the workplace, we unconsciously perceive and interpret communications and events in our own unique ways, leading to the emergence of various emotions. (See Figure 2: Relationship between Events, Perception (Interpretation), Emotions, Physical Responses, and Behavior).
    </Text>

    <Image src={imageUrl_w5} alt="w5" mt="50px" width="94%" />

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Among our emotions, anger, fear, and sadness are constantly present in our daily lives. On one hand, we tend to suppress or avoid these feelings, and on the other hand, we are often driven by these emotions, avoiding facing them directly.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        If we don't confront our emotions, they will not be resolved. For instance, suppressing feelings of anger won't make them disappear. Instead, this pent-up anger can grow, leading to increased aggression. This mindset can result in significant stress, negatively impacting not only one's health and well-being but also deteriorating relationships at work and affecting job performance.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        One tool that can be utilized to address these unpleasant emotions is mindfulness. While some mistakenly believe that mindfulness is a means to distance oneself from emotions, it actually serves the opposite purpose—it helps us connect with our emotions. By adopting a mindfulness approach and paying attention to emotions and physical sensations, one can recognize anger before it intensifies, allowing for conscious choices about how to handle these feelings.
    </Text>

    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        2. Stress Arising from Automatic Thinking
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        We often jump to conclusions about certain events, thinking, "It's bound to happen, and if it does, it will be terrible." This is referred to as automatic thinking, which denotes thoughts that spring up very quickly in response to situations, independent of one's will. These thoughts occur in a mindless state and lead to stress.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Conversely, being mindful means seeking new reasons that suggest the event might "not even happen in the first place, and even if it does, it could actually be beneficial." By adopting this perspective, the associated stress dissipates.
    </Text>

    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        3. Stress Arising from Rumination
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        We sometimes become dejected by recalling painful past experiences. Continuously revisiting and dwelling on these unpleasant memories is referred to as rumination. Rumination is not at all productive, and it is said that those who engage in ruminative thinking tend to be more susceptible to stress.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        However, by becoming mindful, one can enhance meta-cognitive awareness of these thoughts. This awareness allows individuals to recognize and distance themselves from these somewhat automated ruminative thoughts.
    </Text>
    <Text width="94%" mt="40px" fontSize={["10px","12px"]} fontFamily='Open Sans, sans-serif' fontStyle="italic">
        Source: Harvard Business Review, "Teachings from the 'Mother of Mindfulness': Meditation is Not Necessary for 'Awareness.'"
    </Text>
    <Text width="94%" mt="3px" fontSize={["10px","12px"]} fontFamily='Open Sans, sans-serif' fontStyle="italic">
        Source: Emotion Psychology Research, "Does Mindfulness Character Prevent the Deterioration of Rumination?"
    </Text>

    <Box 
        width="94%" 
        minHeight={["100px","150px"]}
        p="8px"
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        top="50px"
    >
        <Flex direction="column" ml={["5px","20px"]}>
        <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
            Feedback from Lay Program Participants
        </Text>
        <UnorderedList spacing={2}fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'> 
            <ListItem fontSize={["10px","12px"]} fontStyle="italic">I realized that I was constantly thinking about the future due to my fear of regret, which led me to create baseless anxieties in the present. Since I've learned to view my current situation and myself in a calm and objective manner, I am now able to directly address the root causes of challenges.</ListItem>
            <ListItem fontSize={["10px","12px"]} fontStyle="italic">This translation aims to convey the content in a clear and fluent manner in English. If there are specific nuances or context you'd like to emphasize, please let me know.</ListItem>
        </UnorderedList>
        </Flex>
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Workplace Communication and Mindfulness
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        In an era marked by complexity and rapid change, what does value-creating communication look like? For teams composed of members with diverse experiences and skills, vibrant communication is indispensable to enhance creativity. While communication among members with differing values, expertise, and positions can sometimes lead to conflicts, healthy conflicts, in fact, boost creativity and productivity. Teams that actively share ideas and seek the most effective approach towards a common goal bring concerns to the forefront and offer perspectives from various angles. This process significantly contributes to both creativity and goal achievement.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" width="94%" mt="70px" position="relative">
        <Box height="100%" width="2px" bg="#151515" position="absolute" left="0" />
        
        <Divider width="100%" bg="#151515" height="0.5px"/>
            <Text color="#151515" ml="20px" mt="20px" mb="20px" fontSize={["12px","16px"]} fontFamily='Open Sans, sans-serif'>
                Factors Hindering Healthy Conflict in the Workplace
            </Text>
        <Divider width="100%" bg="#151515" height="0.5px"/>
    </Box>
    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        1. Minimizing communication to avoid confrontation
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Many might believe that confrontations at work can become significant stressors and therefore should be avoided. Many of us often keep minor grievances or differences of opinion to ourselves, letting situations slide without voicing our concerns. While this might seem like an appropriate decision to keep work flowing smoothly on the surface, it can actually create substantial internal strife. Thoughts such as, "That person always interrupts me. They'll probably interrupt me again if I share my opinion, so I'll just keep it to myself," lead to internal rumination about past events or excessive worry about future occurrences. When these feelings accumulate over time, they can have detrimental effects not only on the individual but also on the entire organization.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","12px"]} fontFamily='Open Sans, sans-serif' fontStyle="italic">
        Source: Harvard Business Review, "Business Communication: Get Over Your Fear of Conflict."
    </Text>

    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        2. Perceiving Differences of Opinion Among Members as Attacks or Threats During Communication
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        During workplace or team communications, interpreting certain inputs, such as a colleague's facial expressions or remarks, as personal attacks or threats can lead to taking offense at every little comment. This perspective causes differences of opinion to be perceived as confrontations or threats. If such personal frictions are left unaddressed, they can escalate into what is termed "relationship conflict," a clash based on emotions. This can have a negative impact on an employee's effectiveness, motivation, and sense of well-being, potentially increasing turnover rates and, ultimately, leading to decreased company profits.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","12px"]} fontFamily='Open Sans, sans-serif' fontStyle="italic">
        Source: Harvard Business Review, "Mindfulness is Also Effective When Introduced to Teams."
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" width="94%" mt="70px" position="relative">
        <Box height="100%" width="2px" bg="#151515" position="absolute" left="0" />
        
        <Divider width="100%" bg="#151515" height="0.5px"/>
            <Text color="#151515" ml="20px" mt="20px" mb="20px" fontSize={["12px","16px"]} fontFamily='Open Sans, sans-serif'>
                Communication That Values Both Yourself and Others
            </Text>
        <Divider width="100%" bg="#151515" height="0.5px"/>
    </Box>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        When we can communicate in an honest and straightforward manner that values both ourselves and the other party, it becomes a shortcut to co-creating value without either side having to compromise or endure. It's vital not to suppress our feelings nor to impose our views on others, but rather to assert ourselves appropriately. This begins with recognizing our current emotions objectively through mindfulness.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Being in a mindless state, where our attention is not "in the present," is also referred to as an autopilot mode where we're either swayed by emotions or suppress them. In business contexts, it can lead to behaviors like staying silent out of fear when dealing with someone we're cautious around, or using aggressive language when irritated by someone we're not concerned about.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        By being mindful and recognizing the voice of our heart, we can truly understand our genuine feelings and can then communicate them honestly and directly to others.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        However, even when we understand our true feelings, we might hesitate to express them. If one has low self-esteem (the power to affirm oneself as they are), they might fear being disliked when asserting themselves, leading to self-loathing. Or, they might become aggressive to negate the guilt they feel from asserting themselves.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Mindfulness comes in handy here as well. When you observe your present fears or feelings of guilt in real-time and objectively, the negative power of these thoughts diminishes.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Honest and straightforward expressions might sometimes be met with resistance from others. In such times, by genuinely wanting to understand the other party's perspective and mindfully listening to their words, hearts can connect, opening new doors for communication.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","12px"]} fontFamily='Open Sans, sans-serif' fontStyle="italic">
        Source: "Mindfulness: A Relationship Textbook - A New Approach to Dealing with Challenging People" by Hideo Fujii, published by Clover Publishing.
    </Text>

    <Box 
        width="94%" 
        padding={["8px","10px"]}
        minHeight={["100px","150px"]} 
        backgroundColor="rgba(240,250,250,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        top="50px"
        mb="40px"
    >
        <Flex direction="column" ml={["5px","20px"]}>
        <Text color="#151515" fontSize={["12px","15px"]} fontFamily="'Open Sans', sans-serif" mb="10px">
            Feedback from Lay Program Participants
        </Text>
        <UnorderedList spacing={2}fontSize={["10px","15px"]} fontFamily='Open Sans, sans-serif'> 
            <ListItem fontSize={["10px","12px"]} fontStyle="italic">I had always perceived myself as objective and compassionate, but I came to realize that it was merely my own desire to be seen as a good person. When I became mindful during conversations, I noticed that I was often preoccupied with myself and not truly seeing beyond that. I also frequently found myself enduring and forcibly accepting what others said, even if it went against my feelings. I've come to understand the importance of observing, from a third-person perspective, why both myself and others adopt certain psychologies (opinions, actions, feelings). Furthermore, I've recognized the significance of accepting these observations without attempting to forcibly control my emotions.</ListItem>
        </UnorderedList>
        </Flex>
    </Box>

    <Box 
        width="90%" 
        height={["40px","60px"]} 
        backgroundColor="rgba(46,177,198,1)" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        position="relative"
        top="50px"
        mt="40px"
    >
        <Text color="white" fontSize={["12px","25px"]} fontFamily='Open Sans, sans-serif' fontWeight="800">
            Reasons for Introducing Mindfulness in Companies
        </Text>
    </Box>

    <Image src={imageUrl_w6} alt="w6" mt="80px" width="90%" />

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Honest and straightforward expressions might sometimes be met with resistance from others. In such times, by genuinely wanting to understand the other party's perspective and mindfully listening to their words, hearts can connect, opening new doors for communication.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Improvement of Well-being
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        "Well-being" refers to a state where both mind and body are fulfilled. A frequently cited definition of well-being comes from the preamble of the WHO (World Health Organization) charter adopted in 1947.
    </Text>
    <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        width="80%" 
        mt="40px"
        border="0.05px solid #c0c0c0" 
        boxShadow="3px 3px 5px rgba(0, 0, 0, 0.1)" // Adjust for the desired shadow effect
    >
            <Text 
                color="rgba(46,177,198,1)"
                mt="20px" 
                mb="20px" 
                fontSize={["12px","13px"]} 
                fontFamily='Open Sans, sans-serif' 
                fontWeight="500"
                textAlign="center"
                fontStyle="italic"
            >
                "Health is a state of complete physical, mental and social well-being and not merely the absence of disease or infirmity."
            </Text>
    </Box>

    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Health is not merely the absence of disease or infirmity but is a state of complete physical, mental, and social well-being. (Translated by the Japan WHO Association) Since the 2010s, this concept has garnered attention from companies both in Japan and abroad. The main reasons for this attention include the following four:
    </Text>

    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        1. One of the goals set by the SDGs
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        The SDGs, or Sustainable Development Goals, are a set of goals established by the United Nations in 2015. Among these 17 goals is "GOOD HEALTH AND WELL-BEING." In Japanese, this translates to "health and well-being for all." An increasing number of companies are actively engaging with the SDGs, making well-being a focal point of attention.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        2. Work-life balance and decent work
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Introducing mindfulness in the workplace can greatly enhance both work-life balance and the objectives of decent work. By practicing mindfulness, individuals can sharpen their focus and boost productivity, making it easier to maintain a balance between professional and personal life. Moreover, mindfulness significantly reduces workplace stress, promoting overall well-being. This clarity in thought and reduced stress further aids in making informed and rational decisions, aligning with the principles of decent work.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        2. Work-life balance
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Implementing mindfulness practices with the aim of improving work-life balance can yield significant benefits for both employees and organizations. Mindfulness encourages individuals to be present in the moment, allowing them to engage deeply in tasks during work hours, leading to increased efficiency and potentially reducing the need for overtime. Additionally, the stress-relieving qualities of mindfulness can help employees detach from work-related anxieties during their personal time, ensuring a clearer boundary between professional and personal life. By fostering a mindful approach, employees can achieve a more harmonious balance, feeling more fulfilled in their work while also enjoying rejuvenating personal time, ultimately contributing to enhanced overall well-being.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","18px"]} fontFamily='Open Sans, sans-serif'>
        3. Decent work
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Incorporating mindfulness practices to further the goal of Decent Work can offer several transformative advantages for organizations. Mindfulness instills a sense of presence and self-awareness, empowering employees to approach their tasks with greater clarity and purpose. This not only enhances job satisfaction but also fosters a more respectful and understanding workplace culture. Mindfulness also serves as a tool for managing work-related stress and preventing burnout, ensuring that employees remain mentally and emotionally well-equipped to fulfill their roles. By promoting a mindful work environment, companies can ensure a more equitable, inclusive, and supportive atmosphere, aligning closely with the principles of Decent Work.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                Mindfulness and Well-being
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Individuals practicing mindfulness experience heightened self-awareness and an increased ability to accept things as they are. Similarly, when a team engages in mindfulness, they develop a stronger recognition of themselves as a cohesive unit and enhance their ability to embrace that identity. It is believed that such teams can better understand their collective purpose, challenges, roles, dynamics, and structure.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Teams that have achieved group-level mindfulness are understood to have a shared comprehension of team objectives and challenges. They recognize the dynamics formed among members and can address them effectively. Moreover, these teams tend to experience fewer harmful internal conflicts and exhibit higher psychological safety.
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Whether operating face-to-face or virtually, teams practicing mindfulness at the collective level have been found to yield more favorable outcomes than those that don't. This trend becomes especially pronounced during times of crisis.
    </Text>

    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="94%" mt="70px">
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
            <Text color="rgba(46,177,198,1)" mt="20px" mb="20px"fontSize={["12px","22px"]} fontFamily='Open Sans, sans-serif' fontWeight="500">
                The Significance of Engaging in Mindfulness as a Team
            </Text>
        <Divider width="100%" bg="rgba(46,177,198,1)" height="1px" />
    </Box>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        While it has been scientifically demonstrated that mindfulness can alleviate stress and anxiety, enhance resilience, divergent thinking, job satisfaction, concentration, and flexibility in leadership behaviors, there are questions regarding its adequacy when considered solely from the perspective of value creation in business. Is it enough for individuals alone to engage in mindfulness?
    </Text>
    <Text width="94%" mt="40px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        Even if an individual diligently practices mindfulness, the potential for success at work, both individually and as a team, can be negated by the team's culture. Hence, there is great significance in engaging in mindfulness as a team.
    </Text>
    <Text width="94%" mt="40px" mb="100px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        For instance, individuals practicing mindfulness experience heightened self-awareness and an increased ability to accept things as they are. Similarly, when a team engages in mindfulness, the team begins to recognize themselves as a cohesive unit, enhancing their ability to embrace this identity. As a result, it can be anticipated that the team, as a whole, will become more attuned to its purpose, challenges, roles, dynamics, and structure.
    </Text>

    </Box>
  </Box>
</Box>
</>
);
};

export default WhyMindfulessPage;
