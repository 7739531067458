import { Box, Image, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useRef } from 'react';
import { Storage, Amplify } from 'aws-amplify';
import awsmobile from "../aws-exports";
import { useDisclosure } from "@chakra-ui/react"; // useNavigateの代わりにuseDisclosureを使用

Amplify.configure(awsmobile)
const MotionBox = motion(Box);

const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

function useIntersectionObserver() {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                console.log('Intersecting:', entry.isIntersecting);
            }, { threshold: 0 } 
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return [ref, isIntersecting];
}

function getFlexDirectionBasedOnPosition(imgPosition) {
    if (imgPosition === 'top') {
      return ['column', 'column']; // あるいは他のリスポンシブな値
    } else {
      return ['column', 'row']; 
    }
  }

  const IntroductionCardNobu = ({imageUrl, altText, header, text, color1, color2, transit, imgPosition = 'top'}) => {
    console.log("c",color1)
    const [ref, visible] = useIntersectionObserver();
    const shouldShow = visible;
    const [isHovered, setIsHovered] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <AnimatePresence>
        <Box>
          <MotionBox
            alignItems="center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref = {ref}
            variants={cardVariants}
            initial="hidden"
            animate={shouldShow ? "visible" : "hidden"}
            exit="exit"
            h= {["120vw", "40vw"]}
            w= {["100vw", "35vw"]}
            mb={["100px","100px"]}
            position={["relative","relative"]}
            display="flex"
            flexDirection={getFlexDirectionBasedOnPosition(imgPosition)} 
            justifyContent="center"
            boxShadow="custom"
            bg="#FFFFFF"
            cursor="pointer" 
            transition="box-shadow 0.2s ease"
            _hover={{
                boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.3), 15px 0px 20px rgba(0, 0, 0, 0.3)",
                "> Box > Image": {
                  transform: "scale(1.2)",
                }
             }}
             onClick={onOpen}
            >
            <Box 
                // overflow="hidden"
                marginBottom="30px"
                // flex={3}
            >
                <Image
                    src={imageUrl}
                    alt={altText} 
                    w = {["140px", "250px"]}
                    h = {["auto", "auto"]}
                    // objectFit="cover"
                    objectFit="contain"
                    borderRadius="50%" 
                    mt={["50px","60px"]}
                    transform={isHovered ? 'scale(1.05)' : 'scale(1)'}
                    transition="transform 0.4s ease" // 画像のサイズ変化をアニメーション化します。
                />
            </Box>
            <Flex direction="column" ml="10px" flex={2} mt="10px" alignItems="center" >
                <Text
                    fontSize={["15px","20px"]}
                    fontWeight="bold"
                    position="center"
                    mt={1}
                    textAlign="center"
                    w="100%"
                >
                Nobu Sugahara
                </Text>
                <Text
                    fontSize={["10px","15px"]}
                    position="center"
                    mt={1}
                    textAlign="center"
                    w="100%"
                >
                Chief Technology Officer
                </Text>
                <Text fontSize={["10px","15px"]} position="relative" ml={5} mt="30px" marginRight="20px" marginBottom="30px">
                        I am Nobu Sugahara, an expert in mindfulness with 20 years of experience in Zen and Mindfulness practices.My current focus is on developing more effective mindfulness techniques through the integration of brainwave technology and neuroscience. In this venture, I am collaborating with Rina, a CEO who shares a similar vision in these fields.
                    </Text>
            </Flex>
        </MotionBox>
        </Box>
     </AnimatePresence>

    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Image
                src={imageUrl}
                alt={altText}
                borderRadius="50%"
                objectFit="cover" // 画像のアスペクト比を維持しつつコンテナに収める
                boxSize="250px" // 例として150pxのサイズを設定、必要に応じて調整
            />
            <Text marginTop="50px">
             I am Nobu Sugahara, with a background as a business manager in high-tech companies. During my tenure in this high-pressure environment, Zen and Mindfulness practices were instrumental in maintaining a state of serene composure, which was crucial for making the right decisions. Owing to my interest in technology, I have monitored and analyzed my own brainwaves to better understand my mental state. Now, I am eager to broaden these experiences.
            </Text>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
    </ModalContent>
    </Modal>
    </>
  );
};

export default IntroductionCardNobu;