import React from "react";
import { Flex, Box, Text,List,Image, Divider, ListItem} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import '../App.css';

const OneDayRetreatJp = ({
  imageUrl_g1,
  imageUrl_g2,
  imageUrl_g3,
  imageUrl_g4,
  imageUrl_g5,
  imageUrl_g6,
  imageUrl_g7,
  imageUrl_g8
  }) => {

    const navigate = useNavigate();

  return (
    <>
    <Box display="flex" justifyContent="center">
     <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" position="relative" width="80%" marginTop="4rem" marginBottom="6rem">
     <Box id="program"></Box>
        <Box 
            p={["0","0"]}
            paddingTop={["30px","200px"]} 
            minHeight={["20vh","30vh","40vh","50vh"]}
            backgroundColor="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["120%","95%"]}
            position="relative"
        >
        <Box
            width="90%"
            height={["75px","150px"]} 
            backgroundColor="rgba(46,177,198,1)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
            marginTop={["50px", "-150px"]} // この行を追加
        >
            <Flex direction="column" alignItems="center">
                <Text color="white" fontSize={["12px","25px"]} fontFamily="'Open Sans', sans-serif" fontWeight="800">
                    1-day マインドフルネス&乗馬リトリート
                </Text>
            </Flex>
        </Box>

        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="40px"
            >
        <Text width="90%" mt="10px" fontSize={["12px","15px"]} fontFamily='Open Sans, sans-serif'>
        この1日のリトリートでは、自然の中での乗馬体験とマインドフルネス瞑想を通じて、
        日々の生活の中で忘れがちな「今」に集中し、自己の内面と向き合うことを目的としています
        乗馬やマインドフルネスの経験がなくても、全ての参加者がリラックスして楽しめるように配慮されたプログラムとなっています。
        開催場所は、雄大な利根川の流れに赤城山を望む群馬の乗馬クラブ。美しい自然の中で馬とのコミュニケーションや河原の散歩を楽しみながら、深い呼吸とともに現在の瞬間に集中する経験をします。
        地元の有機野菜を使用したヴィーガンランチもご用意しており、五感全てで自然を感じ取ることができる特別な日となるでしょう。
        </Text>
        <Text fontWeight="bold" mt="20px" mb={2}>＜目的＞</Text>
        <List styleType="disc" pl={5} mb={4}>
            <ListItem>マインドフルネスの実践 : 忙しい日常から離れ、現在の瞬間に意識を集中させることで、心の平和とクリアな思考を促進します。</ListItem>
            <ListItem>自然とのつながりの再確認 : 自然の中での乗馬や森の散歩を通じて、自然とのつながりを深め、リフレッシュする機会を提供します。</ListItem>
            <ListItem>体と心のリラックス : 瞑想や乗馬を通じて、心身のストレスを解放し、リラックスした状態を体験します。</ListItem>
        </List>
        </Box>

        <Flex 
            width="90%" 
            mt="70px" // 上からのマージンを調整
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g1} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                 10:00 - 10:15
            </Text>
            <Text mt="20px" mb={2}>受付・ウェルカムドリンク</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>参加者がリトリートの場所に到着後、ハーブティーやデトックスウォーターなどのドリンクで歓迎。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g2} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:15 - 10:45
            </Text>
            <Text mt="20px" mb={2}>オープニングセッション</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>リトリートの目的やスケジュールの説明。マインドフルネスとは何か、その効果や意義についての簡単な説明。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g3} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                10:45 - 11:45
            </Text>
            <Text mt="20px" mb={2}>マインドフルネス瞑想</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>自然の中での瞑想セッション。木のざわめきや鳥の声を感じながら、深い呼吸を重ねる。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g4} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                11:45 - 13:15
            </Text>
            <Text mt="20px" mb={2}>乗馬体験</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>馬との初対面からスタート。馬のブラッシングやケアを手伝いながら馬とのコミュニケーションをとる。その後、専門のインストラクターのもと、乗馬体験。自然と一体となりながらの乗馬は、心を解放する効果が。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g5} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                13:15 - 14:15
            </Text>
            <Text mt="20px" mb={2}>ランチタイム</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>地元の有機野菜を使用したヴィーガンランチ。食事もマインドフルに、一口一口味わって食べる。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g6} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                14:15 - 14:45
            </Text>
            <Text mt="20px" mb={2}>河原の散歩</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>香りをゆっくりと歩きながら、自然の音や香りに意識を向ける。マインドフルウォーキングを実践。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g7} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "50% 100%",
                    borderBottomRightRadius: "100% 100%",
                    borderTopLeftRadius: "100% 100%",
                    borderTopRightRadius: "50% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                14:45 - 15:15
            </Text>
            <Text mt="20px" mb={2}>体感ワークショップ</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>マインドフルネスの技法を日常生活に取り入れる方法や、自分の感覚を研ぎ澄ますテクニックを学ぶ。</ListItem>
            </List>
            </Box>
        </Flex>

        <Flex 
            width="90%" 
            mt="70px" 
            justifyContent="space-between"
            alignItems="center"
            flexDirection={["column", "row"]} 
        >
            <Image 
                src={imageUrl_g8} // 画像のURLを指定
                alt="Tea" 
                boxSize="40%" // 画像のサイズを調整
                objectFit="cover"
                height="150px"
                width="200px"
                ml={["0px","50px"]}
                style={{
                    borderBottomLeftRadius: "100% 100%",
                    borderBottomRightRadius: "50% 100%",
                    borderTopLeftRadius: "50% 100%",
                    borderTopRightRadius: "100% 100%"
                  }}
            />
             <Box 
                p={2} 
                backgroundColor="rgba(255,255,255,1)" 
                fontFamily="'Open Sans', sans-serif"
                fontSize={["12px","15px"]}
                width="90%"
                mt={["20px","0px"]}
                ml={["30px","100px"]} 
                >
            <Text width="90%"  mt="10px" fontSize={["12px","20px"]} fontFamily='Open Sans, sans-serif'>
                15:15 - 16:30: クロージング
            </Text>
            <Text mt="20px" mb={2}>シェアリングタイム</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>参加者同士でのシェアリング。今日の体験や感じたこと、学びなどを共有。</ListItem>
            </List>
            <Text mt="20px" mb={2}>クロージングメディテーション</Text>
            <List styleType="disc" pl={5} mb={4}>
                <ListItem>リトリートの終わりを締めくくる瞑想。感謝の気持ちを中心に。</ListItem>
            </List>
            </Box>
        </Flex>

        <Box 
            p={4} 
            backgroundColor="rgba(240,250,250,1)" 
            fontFamily="'Open Sans', sans-serif"
            fontSize={["12px","15px"]}
            width="90%"
            mt="20px"
            mb="20px"
            >
        <Text fontWeight="bold" mt={["0px","20px"]} mb={["10px","20px"]}>補足:</Text>
        <List styleType="disc" pl={5} mb={["10px","20px"]}>
            <ListItem>このリトリートではヨガやマインドフルネスの経験は問いません。内容は初心者から経験者まで対応しています。</ListItem>
        </List>
        </Box>

    </Box>
  </Box>
</Box>
</>
);
};

export default OneDayRetreatJp;