import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import WhyMindfulessPage from './components/WhyMindfulnessPage';
import CorporatePrograms from "./components/CorporatePrograms";
import OneDayRetreat from './components/OneDayRetreat';
import WhoWeAre from "./components/WhoWeAre";
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes,useParams } from 'react-router-dom';
import { ChakraProvider, extendTheme,Box } from "@chakra-ui/react";
import { Amplify, Storage } from 'aws-amplify';
import awsmobile from "./aws-exports";
import ScrollToTop from './ScrollToTop';
import Footer from "./components/Footer";
import CompanyProfile from "./components/CompanyProfile";
import PrivatePolicy from "./components/PrivatePolicy";
import ContactUs from "./components/ContactUs";
import VisionText from "./contents/VisionText.json";
import WhyMindfulnessText from "./contents/WhyMindfulnessText.json";
import AppText from "./contents/AppText.json";
import OneDayRetreatJp from './components/OneDayRetreatJp';
import LSM from './lsm/LSM';

Amplify.configure(awsmobile)

function App() {
  const [desktopVideo, setDesktopVideo] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [imageUrl1, setImageUrl1] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);
  const [imageUrl3, setImageUrl3] = useState(null);
  const [topPageText, setTopPageText] = useState(null);

  const [topPageProps, setTopPageProps] = useState({
    videoUrl: "", 
    videoSamnail:"",
    imageUrl_t1:"",
    imageUrl_t2:"",
    imageUrl_t3:"",
    imageUrl_t4:"",
    imageUrl_t5:"",
    imageUrl_t6:"",
  });

  const [whyMindfulnessProps, setWhyMindfulnessProps] = useState({
    videoUrl: "", 
    videoSamnail:"",
    imageUrl_w1:"",
    imageUrl_w2:"",
    imageUrl_w3:"",
    imageUrl_w4:"",
    imageUrl_w5:"",
    imageUrl_w6:"",
  });

  const [corporateProgramsProps, setCorporateProgramsProps] = useState({
    videoUrl: "", 
    videoSamnail:"",
    imageUrl_p1:"",
    imageUrl_p2:"",
    imageUrl_p3:"",
    imageUrl_p4:"",
    imageUrl_p5:"",
  });

  const [oneDayProgramsProps, setOneDayProgramsProps] = useState({
    videoUrl: "", 
    videoSamnail:"",
    imageUrl_k1:"",
    imageUrl_k2:"",
    imageUrl_k3:"",
    imageUrl_k4:"",
    imageUrl_k5:"",
    imageUrl_k6:"",
    imageUrl_k7:"",
    imageUrl_k8:"",
    imageUrl_g1:"",
    imageUrl_g2:"",
    imageUrl_g3:"",
    imageUrl_g4:"",
    imageUrl_g5:"",
    imageUrl_g6:"",
    imageUrl_g7:"",
    imageUrl_g8:"",
  });

  const [whoWeAreProps, setWhoWeAreProps] = useState({
    videoUrl: "", 
    videoSamnail:"",
    imageUrl_i1:"",
    imageUrl_i2:"",
    imageUrl_i3:"",
    imageUrl_i4:"",
    imageUrl_i5:"",
  });

  const theme = extendTheme({
    styles: {
      global: {
        body: {
          backgroundColor: "#E3E7EB",
          /* 他のスタイル設定... */
        },
      },
    },
    shadows: {
      custom: "-10px -10px 15px rgba(255, 255, 255, 0.7), 10px 10px 15px rgba(0, 0, 0, 0.2)",
    },
    fonts: {
      body: '"Helvetica", system-ui, sans-serif',
      breeSerif: 'Open Sans, sans-serif'
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const desktopVideo = await Storage.get('Lay-Web-Movie-ver3.mp4');
        setDesktopVideo(desktopVideo);
        const mobileImage = await Storage.get('mobileTop.png');
        setMobileImage(mobileImage);
        const topPageText = await Storage.get('toppageText.png');
        setTopPageText(topPageText);
        
        const daoVideoSamnail = await Storage.get('mindfulness1.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, videoSamnail:daoVideoSamnail}));
        setImageUrl1(daoVideoSamnail);

        const imageUrl_t1 = await Storage.get('t1.png');
        setTopPageProps((prevProps) => ({...prevProps, imageUrl_t1:imageUrl_t1}));
        const imageUrl_t2 = await Storage.get('t2.png');
        setTopPageProps((prevProps) => ({...prevProps, imageUrl_t2:imageUrl_t2}));
        const imageUrl_t3 = await Storage.get('K6.png');
        setTopPageProps((prevProps) => ({...prevProps, imageUrl_t3:imageUrl_t3}));
        const imageUrl_t4 = await Storage.get('t4.png');
        setTopPageProps((prevProps) => ({...prevProps, imageUrl_t4:imageUrl_t4}));

        const imageUrl_w1 = await Storage.get('w1.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w1:imageUrl_w1}));
        const imageUrl_w2 = await Storage.get('w2.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w2:imageUrl_w2}));
        const imageUrl_w3 = await Storage.get('w3.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w3:imageUrl_w3}));
        const imageUrl_w4 = await Storage.get('w4.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w4:imageUrl_w4}));
        const imageUrl_w5 = await Storage.get('w5.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w5:imageUrl_w5}));
        const imageUrl_w6 = await Storage.get('w6.png');
        setWhyMindfulnessProps((prevProps) => ({...prevProps, imageUrl_w6:imageUrl_w6}));

        const imageUrl_p1 = await Storage.get('p1.png');
        setCorporateProgramsProps((prevProps) => ({...prevProps, imageUrl_p1:imageUrl_p1}));
        const imageUrl_p2 = await Storage.get('p2.png');
        setCorporateProgramsProps((prevProps) => ({...prevProps, imageUrl_p2:imageUrl_p2}));
        const imageUrl_p3 = await Storage.get('p3.png');
        setCorporateProgramsProps((prevProps) => ({...prevProps, imageUrl_p3:imageUrl_p3}));
        const imageUrl_p4 = await Storage.get('p4.png');
        setCorporateProgramsProps((prevProps) => ({...prevProps, imageUrl_p4:imageUrl_p4}));
        const imageUrl_p5 = await Storage.get('p5.png');
        setCorporateProgramsProps((prevProps) => ({...prevProps, imageUrl_p5:imageUrl_p5}));

        const imageUrl_k1 = await Storage.get('K1.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k1:imageUrl_k1}));
        const imageUrl_k2 = await Storage.get('K2.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k2:imageUrl_k2}));
        const imageUrl_k3 = await Storage.get('K3.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k3:imageUrl_k3}));
        const imageUrl_k4 = await Storage.get('K4.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k4:imageUrl_k4}));
        const imageUrl_k5 = await Storage.get('K5.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k5:imageUrl_k5}));
        const imageUrl_k6 = await Storage.get('K6.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k6:imageUrl_k6}));
        const imageUrl_k7 = await Storage.get('K7.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k7:imageUrl_k7}));
        const imageUrl_k8 = await Storage.get('K8.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_k8:imageUrl_k8}));

        const imageUrl_g1 = await Storage.get('G1.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g1:imageUrl_g1}));
        const imageUrl_g2 = await Storage.get('G2.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g2:imageUrl_g2}));
        const imageUrl_g3 = await Storage.get('G3.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g3:imageUrl_g3}));
        const imageUrl_g4 = await Storage.get('G4.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g4:imageUrl_g4}));
        const imageUrl_g5 = await Storage.get('G5.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g5:imageUrl_g5}));
        const imageUrl_g6 = await Storage.get('G6.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g6:imageUrl_g6}));
        const imageUrl_g7 = await Storage.get('G7.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g7:imageUrl_g7}));
        const imageUrl_g8 = await Storage.get('G8.png');
        setOneDayProgramsProps((prevProps) => ({...prevProps, imageUrl_g8:imageUrl_g8}));

        const imageUrl_i2 = await Storage.get('i2.png');
        setWhoWeAreProps((prevProps) => ({...prevProps, imageUrl_i2:imageUrl_i2}));
        const imageUrl_i3 = await Storage.get('i3.png');
        setWhoWeAreProps((prevProps) => ({...prevProps, imageUrl_i3:imageUrl_i3}));

        setWhoWeAreProps((prevProps) => ({...prevProps, imageUrl_i1:imageUrl_p3}));

      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ScrollToTop /> 
        <Box as="header" position="fixed" top="0" w="100%" zIndex="1">
          <Header />
        </Box>
        <Routes>
          <Route exact path="/" element={
            <Home 
              desktopVideo={desktopVideo} 
              mobileImage={mobileImage}
              imageUrl_t1={topPageProps.imageUrl_t1}
              imageUrl_t2={topPageProps.imageUrl_t2}
              imageUrl_t3={topPageProps.imageUrl_t3}
              imageUrl_t4={topPageProps.imageUrl_t4}
              imageUrl1={imageUrl1} 
              imageUrl2={imageUrl2} 
              imageUrl3={imageUrl3} 
              topPageText={topPageText}
              // articles={articles}
            />
          }/>
         <Route path="/whyMindfulness" element={
            <WhyMindfulessPage
              imageUrl_w1={whyMindfulnessProps.imageUrl_w1}
              imageUrl_w2={whyMindfulnessProps.imageUrl_w2}
              imageUrl_w3={whyMindfulnessProps.imageUrl_w3}
              imageUrl_w4={whyMindfulnessProps.imageUrl_w4}
              imageUrl_w5={whyMindfulnessProps.imageUrl_w5}
              imageUrl_w6={whyMindfulnessProps.imageUrl_w6}
              videoUrl=""
              videoSamnail=""
            />
          }/>

          <Route path="/corporatePrograms" element={
            <CorporatePrograms
              imageUrl_p1={corporateProgramsProps.imageUrl_p1}
              imageUrl_p2={corporateProgramsProps.imageUrl_p2}
              imageUrl_p3={corporateProgramsProps.imageUrl_p3}
              imageUrl_p4={corporateProgramsProps.imageUrl_p4}
              imageUrl_p5={corporateProgramsProps.imageUrl_p5}
              videoUrl=""
              videoSamcorporateProgramsProps
              />
          }/>

          <Route path="/1dayRetreat" element={
            <OneDayRetreat
              imageUrl_k1={oneDayProgramsProps.imageUrl_k1}
              imageUrl_k2={oneDayProgramsProps.imageUrl_k2}
              imageUrl_k3={oneDayProgramsProps.imageUrl_k3}
              imageUrl_k4={oneDayProgramsProps.imageUrl_k4}
              imageUrl_k5={oneDayProgramsProps.imageUrl_k5}
              imageUrl_k6={oneDayProgramsProps.imageUrl_k6}
              imageUrl_k7={oneDayProgramsProps.imageUrl_k7}
              imageUrl_k8={oneDayProgramsProps.imageUrl_k8}
              imageUrl_g1={oneDayProgramsProps.imageUrl_g1}
              imageUrl_g2={oneDayProgramsProps.imageUrl_g2}
              imageUrl_g3={oneDayProgramsProps.imageUrl_g3}
              imageUrl_g4={oneDayProgramsProps.imageUrl_g4}
              imageUrl_g5={oneDayProgramsProps.imageUrl_g5}
              imageUrl_g6={oneDayProgramsProps.imageUrl_g6}
              imageUrl_g7={oneDayProgramsProps.imageUrl_g7}
              imageUrl_g8={oneDayProgramsProps.imageUrl_g8}
              videoUrl=""
              videoSamcorporateProgramsProps
              />
          }/>

      <Route path="/1dayRetreat/jp" element={
            <OneDayRetreatJp
              imageUrl_g1={oneDayProgramsProps.imageUrl_g1}
              imageUrl_g2={oneDayProgramsProps.imageUrl_g2}
              imageUrl_g3={oneDayProgramsProps.imageUrl_g3}
              imageUrl_g4={oneDayProgramsProps.imageUrl_g4}
              imageUrl_g5={oneDayProgramsProps.imageUrl_g5}
              imageUrl_g6={oneDayProgramsProps.imageUrl_g6}
              imageUrl_g7={oneDayProgramsProps.imageUrl_g7}
              imageUrl_g8={oneDayProgramsProps.imageUrl_g8}
              videoUrl=""
              videoSamcorporateProgramsProps
              />
          }/>

           <Route path="/whoWeAre" element={
            <WhoWeAre
              imageUrl_i1={whoWeAreProps.imageUrl_i1}
              imageUrl_i2={whoWeAreProps.imageUrl_i2}
              imageUrl_i3={whoWeAreProps.imageUrl_i3}
              />
          }/>

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/lsm" element={<LSM />} />
         
        {/* <Route path="/article/:id" element={<ArticleDetail />} />
        <Route path="/company" element={<CompanyProfile />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/private-policy" element={<PrivatePolicy />} />
        <Route path="/create" element={<CreateArticle />} /> */}
       </Routes>
       <Box as="footer" position="fixed" bottom="0" w="100%" zIndex="1">
        <Footer />
       </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;

