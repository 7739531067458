/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "layWebsiteApi",
            "endpoint": "https://47g2d1zxhb.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://63jbwn7e5vhjzguz5pb7pnzzxe.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7heb6sqcsja3xdrfzvexjb7uf4",
    "aws_cognito_identity_pool_id": "ap-northeast-1:77643ef5-c6d4-4980-88d3-3de88e3cf92e",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_zDXgeFj2x",
    "aws_user_pools_web_client_id": "6vjuhkvgpakqbk5j666ivmjpmi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "laywebsite12ec3736d5bff466788b08a86aa213f75170616-staging",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
