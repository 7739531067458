import { Box, Flex, Text, Image,Spacer } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import IntroductionCard from './IntroductionCard';
import IntroductionCardNobu from './IntroductionCardNobu';

function WhoWeAre({ 
    imageUrl_i1,
    imageUrl_i2,
    imageUrl_i3
 }) {
  const [imageWidth, setImageWidth] = useState("40%");
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setImageWidth(["80%","70%"]);
      setFirstLoad(false);
    }
  }, [firstLoad]);

  return (
    <>
    <Box 
      w={["100%","100%","100%","100%"]} 
      h={["auto","auto","auto","auto"]} 
      marginTop={["0vh","0vh","0vh","7vh"]} 
      bg="white"
      overflow="hidden"
    >
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Image 
          src={imageUrl_i1}
          alt="Fio Lab."
          w={imageWidth}
          mt={["10vh","10vh","10vh","10vh"]} 
          transition="width 1s ease-in-out"
          style={{
            borderBottomLeftRadius: "50% 100%",
            borderBottomRightRadius: "100% 100%",
            borderTopLeftRadius: "100% 100%",
            borderTopRightRadius: "50% 100%"
          }}
        />
        <Text 
          fontSize={["18px","20px","22px","24px"]} 
          fontWeight="bold" 
          mt={["20px","40px","60px","80px"]}
        >
          Our Vision
        </Text>

      <Box 
        width={["90%","90%"]} 
        justifyContent="center" 
        alignItems="center" 
        display="flex"
      >
        <Text 
          width="90%" 
          fontSize={["12px","14px","16px","18px"]} 
          fontFamily='Open Sans, sans-serif' 
          marginTop={["20px","20px","20px","20px"]}
          marginBottom={["50px","50px","50px","50px"]}
        >
            We have named our company ‘Lay’, inspired by the initials of ‘Live as yourself’. Our overarching vision for Lay is a world where every individual can remain true to their essence while fostering connections with the larger community.Moreover, the name ‘Lay’ also resonates with the word ‘Layer’, symbolizing layers or intertwined connections. At Lay, we firmly believe that amidst the rich tapestry formed by the interweaving of human relationships, where moments of adversity and elation coexist, lies profound learning. 
        </Text>
      </Box>
      </Flex>
      </Box>

      <Flex 
        direction={["column","column","row","row"]} 
        justifyContent="center"
        alignItems="space-between"
        gap={["","","100px","150px"]}
        mb={["100px","100px","100px","100px"]}
      >
            <IntroductionCard
              imageUrl={imageUrl_i2}
              altText="Rina"
              header="Rina Nomura"
              title="Chief Exective Officer, Founder"
              text = "I am Rina Nomura, a mindfulness training expert. I offer mindfulness training that utilizes my business experience and knowledge in psychology to support corporate growth and employee well-being. From managing stress to enhancing teamwork, I am committed to helping unlock your organization’s potential. Let’s work together to build a better future."
              text2 = "I am Rina Nomura, CEO of Lay Corporation. After graduating from university, I gained international business experience at a major machinery component manufacturer listed on the Tokyo Stock Exchange’s First Section. In 2017, I ventured into independence, deepening my expertise in coaching, psychology, and mindfulness, and exploring the transformation of consciousness from various perspectives. In 2018, I studied yoga and meditation in India, and in 2019, founded Lay. I offer mindfulness courses and workshops in multiple languages, along with corporate training. Currently, I am pursuing human studies at Musashino University’s graduate school. I hold an MBA from Globis University, am a certified NLP professional coach, a yoga instructor, and possess various other qualifications."
            />
            <IntroductionCard
              imageUrl={imageUrl_i3}
              altText="Nobu"
              header="Nobu Sugahara"
              title="Chief Technology Officer"
              text = " I am Nobu Sugahara, an expert in mindfulness with 20 years of experience in Zen and Mindfulness practices.My current focus is on developing more effective mindfulness techniques through the integration of brainwave technology and neuroscience. In this venture, I am collaborating with Rina, a CEO who shares a similar vision in these fields."
              text2 = "I am Nobu Sugahara, with a background as a business manager in high-tech companies. During my tenure in this high-pressure environment, Zen and Mindfulness practices were instrumental in maintaining a state of serene composure, which was crucial for making the right decisions. Owing to my interest in technology, I have monitored and analyzed my own brainwaves to better understand my mental state. Now, I am eager to broaden these experiences."
            />
        </Flex>
  </>
  );
}

export default WhoWeAre;