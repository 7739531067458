import { Box, Link, Text, Spacer,Flex, Stack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box as="footer" mt="8rem" py={["0.1rem","0.8rem"]} backgroundColor="rgba(255,255,255,0.95)" color="#D454D7">
      <Flex
        position="relative" 
        direction={["column", "row"]}
        align="center"
        justify={["center","flex-end"]}
        width="100%"
        minHeight="2rem"
        alignItems="center"
        // maxW={{ base: "90%", lg: "60%" }}
        mx="auto"
      >
    
        <Link 
            href="/company"
            color="#151515"
            fontSize={["15px","20px"]}
            mb={["3px","3px"]}
            mr={[0,"30px"]}
            p={["10px", "10px"]}
        >
            {'About Us'}
        </Link>

        <Text color="rgb(28,7,3)" fontSize={["13px","20px"]} mr={["10px","20px"]}>
            @2023 Lay Co.,Ltd.
        </Text>
      </Flex>
    </Box>
  );  
};

export default Footer;
