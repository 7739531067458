import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, Flex, Image, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerBody } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Amplify, Storage } from 'aws-amplify';
import awsmobile from "../aws-exports";
import { useWindowSize } from '../WindowSize';
import { FaBars, FaHome } from 'react-icons/fa';

Amplify.configure(awsmobile);

function Header() {
  const [logo, setLogo] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;

  const HEADER_HEIGHT = '60px';

  useEffect(() => {
    async function fetchData() {
      try {
        const logoURL = await Storage.get('lay_logo.png');
        setLogo(logoURL);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Box 
        bg="rgba(255,255,255,0.9)" 
        position="fixed" 
        height={HEADER_HEIGHT}
        w="100%" 
        zIndex="999" 
        p={3}
      >
        <Flex justifyContent="space-between" alignItems="center">
        <Link to="/">
          <Image src={logo} alt="Lay Logo" height={["30px","40px"]} />
        </Link>
          {isMobile ? (
            <IconButton
              aria-label="Menu"
              icon={<FaBars />}
              onClick={onOpen}
              variant="unstyled"
            />
          ) : (
            <Flex justifyContent="space-between" width="70%" alignItems="center">
              <Link to="/">
                <Text color="#151515" fontSize={["12px","md","xl","16px"]}>
                  Home
                </Text>
              </Link>
              <Link to="/whyMindfulness">
                <Text color="#151515" fontSize={["12px","md","xl","16px"]}>
                  Why Mindfulness
                </Text>
              </Link>
              <Link to="/corporatePrograms">
                <Text color="#151515" fontSize={["12px","md","xl","16px"]}>
                  Corporate Programs
                </Text>
              </Link>
              <Link to="/1dayRetreat">
                <Text color="#151515" fontSize={["12px","md","xl","16px"]}>
                  1-day Retreat
                </Text>
              </Link>
              <Link to="/whoWeAre">
                <Text color="#151515" fontSize={["12px","md","xl","16px"]}>
                  Who We Are
                </Text>
              </Link>
              <Link to="/contact-us">
                <Text marginRight={10} color="#151515" fontSize={["12px","md","xl","16px"]}>
                  Contact Us
                </Text>
              </Link>
            </Flex>
          )}
        </Flex>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent width="100%" maxH={["40vh","37vh","37vh","33vh"]} bg="#FFFFFF" position="relative" marginTop={HEADER_HEIGHT}>
            <DrawerBody
              pt="60px"
              p={4}
              display="flex"
              flexDirection="column"
              alignItems="right"
              justifyContent="right"
              color="white"
            >
              <Link to="/" onClick={onClose} mb={4} isExternal>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg: "rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)" />
                  <Text color="rgba(46,177,198,1)">Home</Text>
                </Flex>
              </Link>
              <Link to="/whyMindfulness" onClick={onClose}>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg:"rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)" />
                  <Text color="rgba(46,177,198,1)">Why Mindfulness</Text>
                </Flex>
              </Link>
              <Link to="/corporatePrograms" onClick={onClose}>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg:"rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)"/>
                  <Text color="rgba(46,177,198,1)">Corporate Program</Text>
                </Flex>
              </Link>
              <Link to="/1dayRetreat" onClick={onClose}>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg:"rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)"/>
                  <Text color="rgba(46,177,198,1)">1-Day Retreat</Text>
                </Flex>
              </Link>
              <Link to="/whoWeAre" onClick={onClose}>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg: "rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)"/>
                  <Text color="rgba(46,177,198,1)">Who We Are</Text>
                </Flex>
              </Link>
              <Link to="/contact-us" onClick={onClose}>
                <Flex
                  direction="row"
                  alignItems="center"
                  bg="transparent"
                  _hover={{ bg: "rgba(46,177,198,1)", color: "black" }}
                  p={2}
                  borderRadius="md"
                >
                  <Box as={FaHome} mr="2" color="rgba(46,177,198,1)"/>
                  <Text color="rgba(46,177,198,1)">Contact Us</Text>
                </Flex>
              </Link>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default Header;
